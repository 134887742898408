<template>
  <div class="leave-detail">
    <div class="topTitle">详情</div>
    <div class="message-items">
        <div class="message-item">
          <div class="message-title">反馈标题</div>
          <div class="message-value">{{detail.title}}</div>
        </div>
        <div class="message-item">
          <div class="message-title">反馈内容</div>
          <div class="message-value">{{detail.content}}</div>
        </div>
        <div class="message-item">
          <div class="message-title">提交时间</div>
          <div class="message-value">{{detail.createTime}}</div>
        </div>
        <div class="message-item" v-if="detail.replyContent">
          <div class="message-title pull-left">反馈回复</div>
          <div class="message-value pull-right">{{detail.replyContent}}</div>
        </div>
      </div>
  </div>
</template>

<script>
import { authorizedFeedback } from "@/api/user/index";
export default {
  data() {
    return {
      detail: {},
      loading: false,
      list: [],
    };
  },
  mounted() {
    if (this.$route.params) {
      let id = this.$route.params.id;
      this.getDetail(id);
    }
  },
  methods: {
    getDetail (feedId) {
      this.loading = true;
      authorizedFeedback({
             method: "/mobile/oneInfo",
             body: JSON.stringify({feedId: feedId}),
          }).then((res) => {
            if (res.data.code == 200) {
              this.loading = false;
              this.detail = res.data.data || {};
            } else {
              this.loading = false;
              this.$alert(
                `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
                "",
                {
                  dangerouslyUseHTMLString: true,
                  center: true,
                  showConfirmButton: false,
                }
              );
            }
          });
    },
  },
};
</script>

<style scoped>
.leave-detail {
  margin: 20px;
}
.message-items{
  margin-top: 40px;
}
.message-item{
  display: flex;
  margin-bottom: 22px;
}
.message-title{
  width: 130px;
  text-align: left;
}
.message-value{
  width: 100%;
  line-height: 25px;
  color: grey;
  font-size: 15px;
}
</style>