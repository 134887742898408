<template>
  <div class="regist">
    <div class="logo_box">
      <img width="100%" src="../assets/img/register_logo.png" alt="" />
    </div>
    <div class="titl">实名注册信息填写</div>
    <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="95px"
      class="demo-ruleForm"
    >
      <el-form-item label="姓名" prop="name">
        <el-input
          v-model="ruleForm.name"
          autocomplete="off"
          placeholder="请输入姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="身份证号码" prop="idCard">
        <el-input
          v-model="ruleForm.idCard"
          autocomplete="off"
          placeholder="请输入身份证号码"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="phonenumber">
        <el-input
          v-model="ruleForm.phonenumber"
          autocomplete="off"
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="verifyCode">
        <el-input
          @blur="checkNumber"
          maxlength="6"
          v-model="ruleForm.verifyCode"
          placeholder="请填写验证码"
        >
          <el-button
            size="mini"
            type="primary"
            slot="suffix"
            style="padding: 5px"
            v-if="countdown == 0"
            @click="getCode"
            >获取验证码</el-button
          >
          <el-button
            style="padding: 5px"
            size="mini"
            type="info"
            slot="suffix"
            v-else
            disabled
            >{{ countdown + "秒后重试" }}
          </el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-button
      style="width: 100%"
      type="primary"
      :loading="loading"
      @click="submitForm('ruleForm')"
      >注册</el-button
    >
  </div>
</template>

<script>
import * as req from "@/api/map/index";
export default {
  data() {
    return {
      loading: false,
      ruleForm: {
        name: "",
        idCard: "",
        phonenumber: "",
        verifyCode: "",
        sessionId: "",
      },
      countdown: 0,
      timer: null,
      rules: {
        name: [
          { required: true, trigger: "blur", message: "请输入您的姓名" },
          {
            pattern: /^[\u4e00-\u9fa5]+$/,
            message: "请输入合法姓名！",
            trigger: "blur",
          },
        ],
        idCard: [
          { required: true, trigger: "blur", message: "请输入您的身份证号码" },
          {
            pattern:
              /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
            message: "请输入正确身份证号码！",
          },
        ],
        phonenumber: [
          { required: true, trigger: "blur", message: "请输入您的电话号码" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确手机号码！",
          },
        ],
        verifyCode: [
          { required: true, trigger: "blur", message: "请输入您收到的验证码" },
        ],
      },
    };
  },
  mounted() {
    if (localStorage.getItem("loginInfo")) {
      this.ruleForm.sessionId = JSON.parse(
        localStorage.getItem("loginInfo")
      ).sessionId;
    }
    this.$forceUpdate();
  },
  methods: {
    checkNumber() {
      // 判断输入的值是否为正整数
      const reg = /^[0-9]\d*$/;
      if (!reg.test(this.ruleForm.verifyCode)) {
        this.ruleForm.verifyCode = "";
      }
    },
    // 提交注册
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          req.register(this.ruleForm).then((res) => {
            if (res.data.code == 200) {
              this.$message.success("提交成功！");
              if (res.data.data.access_token) {
                let loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
                loginInfo.token = res.data.data.access_token;
                localStorage.setItem("loginInfo", JSON.stringify(loginInfo));
                this.$store.commit("SET_TOKEN", res.data.data.access_token);
              }
              this.loading = false;
              this.$router.push({
                name: "faceAuthentication",
                params: {
                  name: this.ruleForm.name,
                  idCard: this.ruleForm.idCard,
                },
              });
            } else {
              this.loading = false;
              this.$alert(
                `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
                "",
                {
                  dangerouslyUseHTMLString: true,
                  center: true,
                  showConfirmButton: false,
                }
              );
            }
          });
        } else {
          return false;
        }
      });
    },
    // 获取验证码
    getCode() {
      if (!this.ruleForm.phonenumber) {
        this.$alert(
          `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">手机号不能为空！</div>`,
          "",
          {
            dangerouslyUseHTMLString: true,
            center: true,
            showConfirmButton: false,
          }
        );
      } else {
        let reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        if (!reg.test(this.ruleForm.phonenumber)) {
          this.$alert(
            `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">请输入正确的手机号！</div>`,
            "",
            {
              dangerouslyUseHTMLString: true,
              center: true,
              showConfirmButton: false,
            }
          );
        } else {
          req
            .verifyCode({ phonenumber: this.ruleForm.phonenumber })
            .then((res) => {
              this.countdown = 60;
              // this.ruleForm.verifyCode = res.data.data;
              this.timer = setInterval(() => {
                if (this.countdown > 0) {
                  this.countdown--;
                } else {
                  clearInterval(this.timer);
                }
              }, 1000);
            });
        }
      }
    },
  },
};
</script>


<style scoped>
.regist {
  margin: 20px;
}
.logo_box {
  width: 55px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.titl {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
  color: #0493f9;
}
form {
  border: none !important;
}
/deep/ form {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 30px;
}
/deep/.el-form-item__label {
  text-align: left;
}
/deep/.el-form-item {
  border-bottom: 1px solid #c2c2c2;
}
/deep/.el-input__inner {
  border: none;
}
</style>