import axios from "axios";
import store from "../store";
import { Notification, MessageBox, Message } from "element-ui";
import { refreshTokenFn } from '@/api/user/index'
import { encrypt } from "@/utils/rsaUtils";
import errorCode from "@/utils/errorCode";
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
const api = axios.create({
  // baseURL:process.env.VUE_APP_BASE_API,
  baseURL: process.env.NODE_ENV === "production" ? "/prod-api" : "/",
  // baseURL:'http://182.140.209.211:20021',
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // 超时
  timeout: 20000,
});

api.interceptors.request.use(
  (config) => {
    // 这里的config包含每次请求的内容
    const token = JSON.parse(localStorage.getItem("loginInfo")).token;
    if (token) {
      // 添加headers
      config.headers.Authorization = token;
    }
    // 对请求参数进行加密处理
    // if (localStorage.getItem("loginInfo")) {
    //   const pubKey = JSON.parse(localStorage.getItem("loginInfo")).publicKey;
    //   config.data = encrypt(JSON.stringify(config.data), pubKey);
    // }
    return config;
  },
  (err) => {
    console.error("api request 请求出错：", err);
    return Promise.reject(err);
  }
);

// 响应拦截器
api.interceptors.response.use(
  (res) => {
    console.log(res, "????");
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode["default"];
    if (code === 401) {
      if (localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo')).name) {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('loginInfo');
        location.href = '/index';
      } else {
        //未注册
        MessageBox.confirm("未实名认证，请进行实名认证", "系统提示", {
          confirmButtonText: "实名认证",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          location.href = "#/register";
        });
      }

    } else if (code == 403) {
      //未人脸识别
      MessageBox.confirm("未人脸认证，请进行人脸认证", "系统提示", {
        confirmButtonText: "人脸认证",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        location.href = "#/faceAuthentication";
      });
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err" + error);
    // return Promise.reject(error)
  }
);
export default api;
