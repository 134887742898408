<template>
  <div class="app">
    <van-image-preview
       v-model="show"
       :images="[imgUrl]"
       :showIndex='showIndex'
       :close-on-click-overlay='clickOverlay'
       :close-on-click-image='clickImage'
       :close='show=true'
       ></van-image-preview>
  </div>
</template>

<script>
import * as req from "@/api/user/index";
import Axios from "axios";
import { appGlobalData } from "@/utils/index";
export default {
  name: "precess",

  data() {
    return {
      imgUrl: '',
      show: true,
      appData: appGlobalData
    };
  },
  props: {
    clickOverlay: {
      type: Boolean,
      default: false
    },
    clickImage: {
      type: Boolean,
      default: false
    },
    showIndex: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.getImg();
  },
  methods: {
    getImg() {
      let url = `${process.env.NODE_ENV === "production" ? "prod-api" : ""}/transfer/common/baseDownload?filePath=${this.appData.filePath}&fileName=${this.appData.fileName}`
      Axios.get(url).then(res => {
        console.log(res.data.data)
        this.imgUrl = 'data:'+res.data.data.mimeType+';base64,' + res.data.data.base64;
      })
      // req.downloadImg({
      //   filePath: 'group1/M00/00/32/CmQA0mX3oo-AfJ3YAAldZKV5EWg515.jpg',
      //   fileName: 'CmQA0mX3oo-AfJ3YAAldZKV5EWg515.jpg',
      // }).then((res) => {
      //   // this.imgUrl = res.data.data.base64;
      //   this.imgUrl = 'data:'+res.data.data.mimeType+';base64,' + res.data.data.base64;
      // });
    },
  },
};
</script>
<style scoped>
</style>