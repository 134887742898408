<template>
  <div class="systemNotice">
    <div class="topTitle">系统通知</div>
    <div class="list">
      <div class="list-item">
        <div>xxxxxxx</div>
        <div>2023-12-01 17:00</div>
        <div style="width: 5%" @click="showDetail">>></div>
      </div>
      <div class="list-item">
        <div>通知通知通知通知通知通知通知通知通知通知通知</div>
        <div>2023-12-01 17:00</div>
        <div style="width: 5%" @click="showDetail">>></div>
      </div>
    </div>
    <el-dialog title="详情" :visible.sync="dialogVisible" width="90%" center>
      <div>{{ detail }}</div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      detail:
        "通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通",
    };
  },
  methods: {
    showDetail() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped>
.systemNotice {
  margin: 20px;
}
.list-item {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
  line-height: 21px;
}
.list-item :first-child {
  width: 55%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.list-item :nth-child(2) {
  width: 43%;
  text-align: right;
  padding-right: 10px;
}
/deep/.el-dialog--center .el-dialog__body {
  text-align: initial;
  padding: 25px 25px 30px;
  max-height: 450px;
  overflow-y: scroll;
}
</style>