import Vue from "vue";
import Router from "vue-router";
import index from "@/views/index";
import process from "@/views/process";
// import login from "@/views/login";
import register from "@/views/register";
import feedBack from "@/views/feedBack";
import DownloadPage from "@/views/DownloadPage";
import map from "@/views/map";
import searchView from "@/views/searchView";
import LifeCycle from "@/views/LifeCycle/index";
import ServiceGuide from "@/views/ServiceGuide/index";
import OnlineApplication from "@/views/OnlineApplication/index";
import BusinessDetails from "@/views/MyOffice/BusinessDetails";
import MyOffice from "@/views/MyOffice/index";
import Personal from "@/views/Personal/index";
import villagesAndTowns from "@/views/villagesAndTowns/index"; //乡镇页面
import resetPassword from "@/views/Personal/resetPassword";
import basicInformation from "@/views/Personal/basicInformation";
import myAddress from "@/views/Personal/myAddress";
import systemNotice from "@/views/Personal/systemNotice";
import leaveMessage from "@/views/Personal/leaveMessage";
import leaveDetail from "@/views/Personal/leaveDetail";
import faceAuthentication from "@/views/Personal/faceAuthentication";
import enterpriseAuthentication from "@/views/Personal/enterpriseAuthentication";
import HanYuan from "@/views/CharacteristicVillage/HanYuan/index";
import AnLe from "@/views/CharacteristicVillage/AnLe/index";
import DaShu from "@/views/CharacteristicVillage/DaShu/index";
import FuLin from "@/views/CharacteristicVillage/FuLin/index";
import FuQuan from "@/views/CharacteristicVillage/FuQuan/index";
import FuXiang from "@/views/CharacteristicVillage/FuXiang/index";
import FuZhuang from "@/views/CharacteristicVillage/FuZhuang/index";
import HeNan from "@/views/CharacteristicVillage/HeNan/index";
import HuangMu from "@/views/CharacteristicVillage/HuangMu/index";
import JiuXiang from "@/views/CharacteristicVillage/JiuXiang/index";
import MaLie from "@/views/CharacteristicVillage/MaLie/index";
import NiMei from "@/views/CharacteristicVillage/NiMei/index";
import PianMa from "@/views/CharacteristicVillage/PianMa/index";
import QianYu from "@/views/CharacteristicVillage/QianYu/index";
import QingXi from "@/views/CharacteristicVillage/QingXi/index";
import ShaiJin from "@/views/CharacteristicVillage/ShaiJin/index";
import ShunHe from "@/views/CharacteristicVillage/ShunHe/index";
import TangJia from "@/views/CharacteristicVillage/TangJia/index";
import WuSiHe from "@/views/CharacteristicVillage/WuSiHe/index";
import XiaoBao from "@/views/CharacteristicVillage/XiaoBao/index";
import YiDong from "@/views/CharacteristicVillage/YiDong/index";
import YongLi from "@/views/CharacteristicVillage/YongLi/index";
import HeadBanner from "@/components/HeadBanner/index";
import FootBanner from "@/components/FootBanner/index";
Vue.component("head-banner", HeadBanner);
Vue.component("foot-banner", FootBanner);
Vue.use(Router);

export default new Router({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "index",
      component: index,
    },
    {
      path: "/process",
      name: "process",
      component: process,
    },
    // {
    //   path: "/login",
    //   name: "login",
    //   component: login,
    // },
    {
      path: "/register",
      name: "register",
      component: register,
    },
    {
      path: "/feedBack",
      name: "feedBack",
      component: feedBack,
    },
    {
      path: "/DownloadPage",
      name: "DownloadPage",
      component: DownloadPage,
      // props: true
    },
    {
      path: "/map",
      name: "map",
      component: map,
    },
    {
      path: "/searchView",
      name: "searchView",
      component: searchView,
    },
    {
      path: "/villagesAndTowns",
      name: "villagesAndTowns",
      component: villagesAndTowns,
    },
    {
      path: "/LifeCycle",
      name: "LifeCycle",
      component: LifeCycle,
    },
    {
      path: "/ServiceGuide",
      name: "ServiceGuide",
      component: ServiceGuide,
    },
    {
      path: "/OnlineApplication",
      name: "OnlineApplication",
      component: OnlineApplication,
    },
    {
      path: "/MyOffice",
      name: "MyOffice",
      component: MyOffice,
    },
    {
      path: "/BusinessDetails",
      name: "BusinessDetails",
      component: BusinessDetails,
    },
    {
      path: "/Personal",
      name: "Personal",
      component: Personal,
    },
    {
      path: "/resetPassword",
      name: "resetPassword",
      component: resetPassword,
    },
    {
      path: "/basicInformation",
      name: "basicInformation",
      component: basicInformation,
    },
    {
      path: "/myAddress",
      name: "myAddress",
      component: myAddress,
    },
    {
      path: "/systemNotice",
      name: "systemNotice",
      component: systemNotice,
    },
    {
      path: "/leaveMessage",
      name: "leaveMessage",
      component: leaveMessage,
    },
    {
      path: "/leaveDetail",
      name: "leaveDetail",
      component: leaveDetail,
    },
    {
      path: "/faceAuthentication",
      name: "faceAuthentication",
      component: faceAuthentication,
    },
    {
      path: "/enterpriseAuthentication",
      name: "enterpriseAuthentication",
      component: enterpriseAuthentication,
    },
    {
      path: "/CharacteristicVillage/HanYuan",
      name: "HanYuan",
      component: HanYuan,
    },
    {
      path: "/CharacteristicVillage/AnLe",
      name: "AnLe",
      component: AnLe,
    },
    {
      path: "/CharacteristicVillage/DaShu",
      name: "DaShu",
      component: DaShu,
    },
    {
      path: "/CharacteristicVillage/FuLin",
      name: "FuLin",
      component: FuLin,
    },
    {
      path: "/CharacteristicVillage/FuQuan",
      name: "FuQuan",
      component: FuQuan,
    },
    {
      path: "/CharacteristicVillage/FuXiang",
      name: "FuXiang",
      component: FuXiang,
    },
    {
      path: "/CharacteristicVillage/FuZhuang",
      name: "FuZhuang",
      component: FuZhuang,
    },
    {
      path: "/CharacteristicVillage/HeNan",
      name: "HeNan",
      component: HeNan,
    },
    {
      path: "/CharacteristicVillage/HuangMu",
      name: "HuangMu",
      component: HuangMu,
    },
    {
      path: "/CharacteristicVillage/JiuXiang",
      name: "JiuXiang",
      component: JiuXiang,
    },
    {
      path: "/CharacteristicVillage/MaLie",
      name: "MaLie",
      component: MaLie,
    },
    {
      path: "/CharacteristicVillage/NiMei",
      name: "NiMei",
      component: NiMei,
    },
    {
      path: "/CharacteristicVillage/PianMa",
      name: "PianMa",
      component: PianMa,
    },
    {
      path: "/CharacteristicVillage/QianYu",
      name: "QianYu",
      component: QianYu,
    },
    {
      path: "/CharacteristicVillage/QingXi",
      name: "QingXi",
      component: QingXi,
    },
    {
      path: "/CharacteristicVillage/ShaiJin",
      name: "ShaiJin",
      component: ShaiJin,
    },
    {
      path: "/CharacteristicVillage/ShunHe",
      name: "ShunHe",
      component: ShunHe,
    },
    {
      path: "/CharacteristicVillage/TangJia",
      name: "TangJia",
      component: TangJia,
    },
    {
      path: "/CharacteristicVillage/WuSiHe",
      name: "WuSiHe",
      component: WuSiHe,
    },
    {
      path: "/CharacteristicVillage/XiaoBao",
      name: "XiaoBao",
      component: XiaoBao,
    },
    {
      path: "/CharacteristicVillage/YiDong",
      name: "YiDong",
      component: YiDong,
    },
    {
      path: "/CharacteristicVillage/YongLi",
      name: "YongLi",
      component: YongLi,
    },
    // 对于所有的（*代表所有）错误页面，我们都可以重定向到 "/" 中
    {
      path: "*",
      redirect: "/",
    },
  ],
});
//获取原型对象上的push函数
const originalPush = Router.prototype.push;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
