<template>
  <div class="leave-message">
    <div class="topTitle">我的反馈</div>
    <div class="list">
      <div class="list-item" v-for="(item, index) in list" :key="'item-'+index" @click="showDetail(item)">
        <div>{{item.title}}</div>
        <div>{{parseTime(item.createTime, "{y}-{m}-{d} {h}:{i}")}}</div>
        <div style="width: 20px">>></div>
      </div>
      <el-empty v-show="list.length == 0" description="暂无反馈"></el-empty>
      <!-- <div class="list-item">
        <div>通知通知通知通知通知通知通知通知通知通知通知</div>
        <div>2023-12-01 17:00</div>
        <div style="width: 20px" @click="showDetail">>></div>
      </div> -->
    </div>
    <!-- <el-dialog title="详情" :visible.sync="dialogVisible" width="90%" center>
      <div class="message-items">
        <div class="message-item">
          <div class="message-title">留言标题</div>
          <div class="message-value">{{detail.title}}</div>
        </div>
        <div class="message-item">
          <div class="message-title">留言内容</div>
          <div class="message-value">{{detail.content}}</div>
        </div>
        <div class="message-item">
          <div class="message-title">提交时间</div>
          <div class="message-value">{{detail.createTime}}</div>
        </div>
        <div class="message-item" v-if="detail.replyContent">
          <div class="message-title pull-left">留言回复</div>
          <div class="message-value pull-right">{{detail.replyContent}}</div>
        </div>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import { authorizedFeedback } from "@/api/user/index";
export default {
  data() {
    return {
      dialogVisible: false,
      detail: {},
      loading: false,
      userInfo: {},
      list: [],
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.getList();
  },
  methods: {
    getList () {
      this.loading = true;
      authorizedFeedback({
             method: "/mobile/queryFeedbackInfo",
             body: JSON.stringify({phoneNumber: this.userInfo.phonenumber}),
          }).then((res) => {
            if (res.data.code == 200) {
              this.loading = false;
              this.list = res.data.data || [];
            } else {
              this.loading = false;
              this.$alert(
                `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
                "",
                {
                  dangerouslyUseHTMLString: true,
                  center: true,
                  showConfirmButton: false,
                }
              );
            }
          });
    },
    showDetail(item) {
      // this.dialogVisible = true;
      // this.detail = item || {};
      this.$router.push({
        name: "leaveDetail",
        params: {id: item.feedId},
      });
    },
  },
};
</script>

<style scoped>
.leave-message {
  margin: 20px;
}
.list-item {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
  line-height: 21px;
}
.list-item :first-child {
  width: 55%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.list-item :nth-child(2) {
  width: 43%;
  text-align: right;
  padding-right: 10px;
}
.message-item{
  display: flex;
  margin-bottom: 25px;
}
.message-title{
  width: 110px;
  text-align: left;
}
.message-value{
  width: 100%;
}
.empty {
  margin: 60px;
  text-align: center;
}
.empty img {
  width: 100%;
  margin-bottom: 20px;
}
/deep/.el-dialog--center .el-dialog__body {
  text-align: initial;
  padding: 25px 25px 30px;
  max-height: 450px;
  overflow-y: scroll;
}
</style>