<template>
  <div class="myAddress">
    <div class="topTitle">我的地址</div>
    <div class="topRightTitle" @click="addAddress">
      <i class="el-icon-circle-plus"></i> 新增地址
    </div>
    <div class="addressList">
      <ul>
        <li
          class="addressList-item"
          v-for="item in List"
          :key="item.userAddressId"
        >
          <div class="addressList_item_desc">
            <el-badge
              style="font-size: xx-small"
              v-if="item.isDefault == 1"
              value="默认"
              class="item"
            ></el-badge>
            {{ item.province + item.city + item.area + item.detailAddress }}
          </div>
          <div class="addressList_item_icon">
            <i
              style="margin-right: 20px; color: #0091fa"
              class="el-icon-edit"
              @click="editAddress(item)"
            ></i>
            <i
              class="el-icon-delete-solid"
              style="color: #7f7f7f"
              @click="delAddress(item.userAddressId)"
            ></i>
          </div>
        </li>
      </ul>
      <el-empty v-show="List.length == 0" description="暂无地址"></el-empty>
    </div>
    <!-- 新增/修改地址 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="90%" center>
      <el-form
        ref="ruleForm"
        :rules="rules"
        :model="ruleForm"
        label-width="110px"
      >
        <el-form-item label="联系人" prop="receiverName">
          <el-input
            v-model="ruleForm.receiverName"
            autocomplete="off"
            placeholder="请输入联系人姓名"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="receiverPhone">
          <el-input
            v-model.number="ruleForm.receiverPhone"
            placeholder="请输入联系电话"
            required
          ></el-input>
        </el-form-item>
        <el-form-item label="所在地区" prop="province">
          <!-- <el-form-item label="所在地区" prop="region"> -->
          <el-cascader
            style="width: 100%"
            v-model="region"
            :options="regionData"
            ref="cascader1"
            placeholder="请选择省市区"
            @change="handleChange"
            clearable
          />
          <!-- </el-form-item> -->
          <!-- <el-row>
            <el-col :span="8"
              ><el-select v-model="ruleForm.province" placeholder="省">
                <el-option
                  v-for="item in province"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option> </el-select
            ></el-col>
            <el-col :span="8"
              ><el-select v-model="ruleForm.city" placeholder="市">
                <el-option
                  v-for="item in city"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option> </el-select
            ></el-col>
            <el-col :span="8"
              ><el-select v-model="ruleForm.area" placeholder="县">
                <el-option
                  v-for="item in area"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option> </el-select
            ></el-col>
          </el-row> -->
        </el-form-item>
        <el-form-item label="详细地址" prop="detailAddress">
          <el-input
            v-model.trim="ruleForm.detailAddress"
            placeholder="请输入详细地址"
            required
          ></el-input>
        </el-form-item>
        <el-form-item label="设为默认地址" prop="isDefault">
          <el-radio v-model="ruleForm.isDefault" label="1">是</el-radio>
          <el-radio v-model="ruleForm.isDefault" label="0">否</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm()">重 置</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { regionData } from "element-china-area-data";
import * as req from "@/api/user/index";
export default {
  data() {
    return {
      regionData,
      title: "编辑地址",
      dialogVisible: false,
      ruleForm: {
        receiverName: "",
        receiverPhone: "",
        province: "",
        provinceCode: "",
        city: "",
        cityCode: "",
        area: "",
        areaCode: "",
        detailAddress: "", //详细地址
        isDefault: "",
      },
      region: [],
      rules: {
        receiverName: [
          { required: true, trigger: "blur", message: "请输入您的姓名" },
          {
            pattern: /^[\u4e00-\u9fa5]+$/,
            message: "请输入合法姓名！",
            trigger: "blur",
          },
        ],
        receiverPhone: [
          { required: true, trigger: "blur", message: "请输入您的电话号码" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确手机号码！",
          },
        ],
        province: [
          { required: true, trigger: "change", message: "请选择地区" },
        ],
        detailAddress: [
          { required: true, trigger: "blur", message: "请输入详细地址" },
        ],
        isDefault: [
          { required: true, trigger: "change", message: "请选择默认地址" },
        ],
      },
      List: [], //地址列表
      status: "", //地址编辑（edit）或新增(add)状态
    };
  },
  mounted() {
    this.getAddressList();
    this.$forceUpdate();
  },
  methods: {
    // 获取地址列表
    getAddressList() {
      req.AddressList().then((res) => {
        if (res.data.code == 200) {
          this.List = res.data.data;
        } else {
          this.$alert(
            `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
            "",
            {
              dangerouslyUseHTMLString: true,
              center: true,
              showConfirmButton: false,
            }
          );
        }
      });
      this.$forceUpdate();
    },
    // 选择地区
    handleChange(value) {
      let labelArr = this.$refs["cascader1"].getCheckedNodes()[0].pathLabels;
      this.ruleForm.province = labelArr[0];
      this.ruleForm.city = labelArr[1];
      this.ruleForm.area = labelArr[2];
      this.ruleForm.provinceCode = value[0];
      this.ruleForm.cityCode = value[1];
      this.ruleForm.areaCode = value[2];
    },
    // 新增地址
    addAddress() {
      this.title = "新增地址";
      this.status = "add";
      this.resetForm();
      this.dialogVisible = true;
    },
    // 提交地址
    submitForm(formName) {
      let that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          // 新增
          if (that.status == "add") {
            req.addAddress(that.ruleForm).then((res) => {
              if (res.data.code == 200) {
                that.$message({
                  type: "success",
                  message: "操作成功!",
                });
                that.dialogVisible = false;
                that.getAddressList();
              } else {
                that.$alert(
                  `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
                  "",
                  {
                    dangerouslyUseHTMLString: true,
                    center: true,
                    showConfirmButton: false,
                  }
                );
                that.dialogVisible = false;
              }
            });
          } else {
            req.editAddress(that.ruleForm).then((res) => {
              if (res.data.code == 200) {
                that.$message({
                  type: "success",
                  message: "操作成功!",
                });
                that.getAddressList();
                that.dialogVisible = false;
              } else {
                that.$alert(
                  `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
                  "",
                  {
                    dangerouslyUseHTMLString: true,
                    center: true,
                    showConfirmButton: false,
                  }
                );
                that.dialogVisible = false;
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    // 修改地址
    editAddress(item) {
      this.title = "编辑地址";
      this.status = "edit";
      this.ruleForm = item;
      this.region = [];
      this.region.push(item.provinceCode, item.cityCode, item.areaCode);
      this.dialogVisible = true;
    },
    // 重置表单
    resetForm() {
      this.ruleForm = {
        receiverName: "",
        receiverPhone: "",
        province: "",
        provinceCode: "",
        city: "",
        cityCode: "",
        area: "",
        areaCode: "",
        detailAddress: "", //详细地址
        isDefault: "",
      };
      this.region = [];
    },
    // 删除地址
    delAddress(userAddressId) {
      this.$confirm("此操作将永久删除该地址, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          req.delAddress({ userAddressId }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getAddressList();
              this.$forceUpdate();
            } else {
              this.$alert(
                `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
                "",
                {
                  dangerouslyUseHTMLString: true,
                  center: true,
                  showConfirmButton: false,
                }
              );
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
    
<style scoped>
.myAddress {
  margin: 20px;
}
.topRightTitle {
  position: absolute;
  right: 10px;
  top: 20px;
  color: #0091fa;
}
.addressList-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #d2d2d2;
  font-size: 14px;
  margin: 10px 0;
}
.addressList_item_desc {
  width: 75%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.addressList_item_icon {
  width: 20%;
  font-size: 16px;
}
/deep/ form {
  border-radius: 5px;
  margin-bottom: 30px;
}
/deep/.el-form-item__label {
  text-align: left;
}
/deep/.el-form-item {
  border-bottom: 1px solid #c2c2c2;
}
/deep/.el-input__inner {
  border: none;
}
</style>