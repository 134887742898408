<template>
  <div class="FuZhuang" v-loading="loading">
    <!--春季：1-3月-->
    <div class="spring" v-if="nowMoth >= 3 && nowMoth <= 5">
      <div class="banner">
        <!-- <img
          width="100%"
          src="../../../assets/img/FuZhuang/spring_banner.jpg"
          alt=""
          srcset=""
        /> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in springImgList" :key=index>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/FuZhuang/spring_jianjie.png" alt="">
        <div class="desc">
          富庄镇甜樱桃种植面积约11000亩，冬暖夏凉，四季分明。海拔1100-1700米的种植范围、1475.8小时
          的年均日照时数、山地影响形成的昼夜温差，和远离城市工业污染的良好生态环境，为甜樱桃的生长提供了绝佳的自然气候条件，形成了果实鲜艳、果皮弹牙、果肉肥厚、味道酸甜适口，可溶性固形物含量在15-24%，单果重10-12克的解馋佳品。因其营养丰富、医养保健价值充沛，被誉为“果中珍品”和“宝石水果”。
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px;text-align:center;">
          <img src="../../../assets/img/red.png" alt="">
          <div class="bottom_br"></div>
        </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#CB221A"
      ></GovernmentServices>
    </div>
    <!-- 四季 -->
    <div class="winter" v-else>
      <div class="banner">
        <!-- <img
          width="100%"
          src="../../../assets/img/FuZhuang/banner.jpg"
          alt=""
          srcset=""
        /> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in sunmmerImgList" :key=index>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/FuZhuang/jianjie.png" alt="">
        <div class="desc">
          富庄镇同义村种植的汉源大白梨树(以下简称古梨树)，栽培历史悠久，具有果实大、抗性强、产量高、耐贮存的优点，是汉源优良的地方梨品种。富庄镇同义村有100年以上古梨树189株，50-100年古梨树701株，共计890株。古树名木是自然历史文化遗产的重要组成部分，是活的文物，具有重要的生态、科研、人文、地理、旅游等方面价值。
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px;text-align:center;">
          <img src="../../../assets/img/blue.png" alt="">
          <div class="bottom_br"></div>
        </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#1D68AB"
      ></GovernmentServices>
    </div>
  </div>
</template>
<script>


import GovernmentServices from "@/components/GovernmentServices/index";
// import { getServerDate, matterQuery, getVillages } from "@/api/map/index"
import { mixin } from "../../../mixin";
export default {
  components: {  GovernmentServices },
  data() {
    return {
      matterList: [], //事项列表
      villages: [], //乡镇列表
      name: "富庄镇",
      keywords: "",
      springImgList: [
        {imgUrl:require('../../../assets/img/FuZhuang/banner.jpg')},
        {imgUrl:require('../../../assets/img/FuZhuang/spring_banner.jpg')},
      ],
      sunmmerImgList: [
        {imgUrl:require('../../../assets/img/FuZhuang/banner.jpg')},
        {imgUrl:require('../../../assets/img/FuZhuang/summer_banner.jpg')},
      ]
    };
  },
  mixins: [mixin],
  created() {
    this.$emit("public_header", false);
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  min-height: 215px;
  max-height: 427px;
}
.banner img{
  height: auto;
}
.con1 {
  position: relative;
  margin-top: -5px;
}
.con1 img{
  width: 100%;
}
.desc {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 29%;
  overflow-y: auto;
  text-indent: 1em;
  width: 64%;
  height: 65%;
}
.search-box {
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
/deep/.spring .el-input__suffix {
  color: #cb221a;
  font-size: 20px;
}
/deep/.winter .el-input__suffix {
  color: #1d68ab;
  font-size: 20px;
}
/deep/.el-carousel__indicator.is-active button {
  background-color: #cb221a;
}
/deep/.el-carousel__button {
  background-color: gray;
}
</style>