<template>
  <div class="guide-container" v-loading="loading">
    <div class="row" v-for="(item, index) in model" :key="index">
      <p class="p" v-if="item.questionContent">{{ item.questionContent }}</p>
      <div class="switch flex-row">
        <div
          @click="onSwitch(index, indexs, items)"
          v-for="(items, indexs) in item.optionList"
          :key="indexs"
          :class="items.isSelected == '1' ? 'call' : ''"
        >
          {{ items.optionContent }}
        </div>
      </div>
      <div
        style="margin-left: 35px"
        v-for="(items, indexs) in item.optionList"
        :key="indexs"
      >
        <div style="margin-left: -25px">
          <guide ref="guide" :model="items.optionList" />
        </div>
      </div>
    </div>
  </div>
</template>
        
<script>
import { getOneLevelQuestionnaire } from "@/api/map/index";

export default {
  name: "Guide",
  props: {
    model: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      question: [],
      loading: false,
    };
  },
  methods: {
    onSwitch(index, indexs, val) {
      this.loading = true;
      for (let i of this.model[index].optionList) {
        i.isSelected = "0";
      }
      for (let i of this.model[index].optionList) {
        i.optionList = [];
      }
      val.isSelected = "1";
      let obj = {
        deptMatterId: val.deptMatterId,
        parentId: val.questionId,
      };
      getOneLevelQuestionnaire({
        method: "/mobile/getOneLevelQuestionnaire",
        body: JSON.stringify(obj),
      })
        .then((res) => {
          this.loading = false;
          this.model[index].optionList[indexs].optionList = res.data.data;
        })
        .catch((err) => {
          this.loading = false;
        });
      this.$emit("toGuide", this.model);
    },
  },
};
</script>
<style lang="scss" scoped>
.guide-container {
  box-sizing: border-box;
}
.row {
  overflow-y: auto;
}
.flex-row {
  display: flex;
  align-items: center;
}
.switch {
  flex-wrap: wrap;
}
.p {
  font-size: 14px;
  color: #333;
  margin: 0;
  padding-bottom: 10px;
  text-align: left;
}
.switch div {
  min-width: 90px;
  height: 25px;
  line-height: 25px;
  padding: 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  text-align: center;
  color: rgb(0, 111, 232);
  border: 1px solid rgb(0, 111, 232);
  margin: 0px 15px 10px 0;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
.switch .call {
  color: #fff !important;
  background: rgb(38, 111, 232) !important;
}
</style>
        