<template>
  <div class="basicInfo">
    <div class="topTitle">基本资料</div>
    <el-form label-width="95px" class="demo-ruleForm">
      <el-form-item label="姓名" prop="name">
        <el-input
          disabled
          v-model="$store.state.name"
          autocomplete="off"
          placeholder="请输入姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="身份证号码" prop="idCard">
        <el-input
          disabled
          v-model="$store.state.idCard"
          autocomplete="off"
          placeholder="请输入身份证号码"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="phonenumber">
        <el-input
          disabled
          v-model="$store.state.phonenumber"
          autocomplete="off"
          placeholder="请输入手机号"
        >
          <el-button
            size="mini"
            type="primary"
            slot="suffix"
            @click="showChangePhone"
            >修改手机号</el-button
          >
        </el-input>
      </el-form-item>
      <el-form-item label="人脸认证" prop="faceAuth">
        <el-input
          disabled
          :value="$store.state.faceAuth == '1' ? '已认证' : '未认证'"
          autocomplete="off"
        >
          <el-button
            size="mini"
            type="primary"
            v-show="$store.state.faceAuth == '0'"
            slot="suffix"
            @click="toFaceAuth"
            >人脸认证</el-button
          >
        </el-input>
      </el-form-item>
    </el-form>
    <!-- 修改手机号弹窗 -->
    <el-dialog
      title="修改手机号"
      :visible.sync="dialogVisible"
      width="90%"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        style="border: none"
        label-width="80px"
      >
        <el-form-item label="手机号码" prop="phonenumber">
          <el-input
            v-model.number="ruleForm.phonenumber"
            autocomplete="off"
            placeholder="请输入新的手机号"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="verifyCode">
          <el-input
            @blur="checkNumber"
            maxlength="6"
            v-model="ruleForm.verifyCode"
            placeholder="请输入验证码"
            required
          >
            <el-button
              v-if="countdown == 0"
              style="padding: 5px"
              size="mini"
              type="primary"
              slot="suffix"
              @click="getCode"
              >获取验证码</el-button
            >
            <el-button
              style="padding: 5px"
              size="mini"
              type="info"
              slot="suffix"
              v-else
              disabled
              >{{ countdown + "秒后重试" }}
            </el-button>
          </el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changePhone">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import * as req from "@/api/user/index";
import * as api from "@/api/map/index";
export default {
  data() {
    return {
      ruleForm: {
        phonenumber: "", //新手机号
        verifyCode: "", //验证码
      },
      dialogVisible: false,
      rules: {
        phonenumber: [
          { required: true, trigger: "blur", message: "请输入您新的电话号码" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确手机号码！",
          },
        ],
        verifyCode: [
          { required: true, trigger: "blur", message: "请输入您收到的验证码" },
        ],
      },
      countdown: 0,
      timer: null,
    };
  },
  methods: {
    showChangePhone() {
      this.ruleForm.phonenumber = "";
      this.ruleForm.verifyCode = "";
      this.dialogVisible = true;
    },
    // 修改手机号
    changePhone() {
      req.editPhoneNumber(this.ruleForm).then((res) => {
        console.log(res, "修改手机号");
        if (res.data.code == 200) {
          this.$message({
            message: "修改成功！",
            type: "success",
          });
          this.dialogVisible = false;
          this.getUserInfo();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 获取验证码
    getCode() {
      if (!this.ruleForm.phonenumber) {
        this.$alert(
          `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">手机号不能为空！</div>`,
          "",
          {
            dangerouslyUseHTMLString: true,
            center: true,
            showConfirmButton: false,
          }
        );
      } else {
        let reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        if (!reg.test(this.ruleForm.phonenumber)) {
          this.$alert(
            `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">请输入正确的手机号！</div>`,
            "",
            {
              dangerouslyUseHTMLString: true,
              center: true,
              showConfirmButton: false,
            }
          );
        } else {
          api
            .verifyCode({ phonenumber: this.ruleForm.phonenumber })
            .then((res) => {
              // console.log(res, "验证码");
              this.countdown = 60;
              this.timer = setInterval(() => {
                if (this.countdown > 0) {
                  this.countdown--;
                } else {
                  clearInterval(this.timer);
                }
              }, 1000);
            });
        }
      }
    },
    checkNumber() {
      // 判断输入的值是否为正整数
      const reg = /^[0-9]\d*$/;
      if (!reg.test(this.ruleForm.verifyCode)) {
        this.ruleForm.verifyCode = "";
      }
    },
    // 获取用户信息
    getUserInfo() {
      api
        .userInfo({
          sessionId: JSON.parse(localStorage.getItem("loginInfo")).sessionId,
        })
        .then((res) => {
          if ((res.data.code = 200)) {
            if (res.data.data.sysUserVO && res.data.data.sysUserVO.name) {
              //已注册
              this.$store.commit("SET_NAME", res.data.data.sysUserVO.name);
              this.$store.commit(
                "SET_FACEAUTH",
                res.data.data.sysUserVO.faceAuth
              );
              this.$store.commit(
                "SET_PHONE",
                res.data.data.sysUserVO.phonenumber
              );
              this.$store.commit("SET_IDCARD", res.data.data.sysUserVO.idCard);
              localStorage.setItem(
                "userInfo",
                JSON.stringify({
                  name: res.data.data.sysUserVO.name,
                  phonenumber: res.data.data.sysUserVO.phonenumber,
                  idCard: res.data.data.sysUserVO.idCard,
                  faceAuth: res.data.data.sysUserVO.faceAuth,
                })
              );
            } else if (res.data.data.sysUserVO.realAuth != "1") {
              localStorage.removeItem("userInfo");
              // this.$router.push("/register");
            }
          } else {
            this.$alert(
              `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
              "",
              {
                dangerouslyUseHTMLString: true,
                center: true,
                showConfirmButton: false,
              }
            );
          }
        });
    },
    // 人脸认证
    toFaceAuth() {
      this.$router.push("/faceAuthentication");
    },
  },
};
</script>
  
  
  <style scoped>
.basicInfo {
  margin: 20px;
}
form {
  border: none !important;
}
/deep/ form {
  border: 1px solid gray;
  border-radius: 5px;
  margin-bottom: 30px;
}
/deep/.el-form-item__label {
  text-align: left;
}
/deep/.el-form-item {
  border-bottom: 1px solid #c2c2c2;
}
/deep/.el-input__inner {
  border: none;
}
/deep/.el-input.is-disabled .el-input__inner {
  color: #000;
}
</style>