<template>
  <div class="HeNan" v-loading="loading">
    <!-- 秋季：9-11月 -->
    <div class="autumn" v-if="nowMoth >= 9 && nowMoth <= 11">
      <div class="banner">
        <!-- <img
          width="100%"
          src="../../../assets/img/HeNan/autumn_banner.jpg"
          alt=""
          srcset=""
        /> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in autumnImgList" :key=index>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/HeNan/autumn_jianjie.png" alt="">
          <div class="desc">
            <p>
              金秋十月，在汉源县河南乡平等村，群山苍翠，绿树成荫，辛勤了一年的养蜂人苟平正在收获今年的“甜蜜”，成群的蜜蜂正“嗡嗡”地围着他在空中飞舞。
            </p>
            <p>
              来到河南乡平等村苟平家养蜂地，苟平正拿着工具取蜜。他找到能取蜜的蜂桶后，用烧出的浓烟驱走蜜蜂，再打开密封住的蜂桶，蜂桶里色香诱人的蜂蜜便呈现在眼前。据苟平介绍，他家三代人养蜂，采用的是最原始的养蜂技术，在百花齐放的三月将蜂桶背到自家后山上，在收获的八月又将蜂桶背下山取蜜后售卖。因为蜂蜜味道纯正，苟平家的蜂蜜吸引了不少回头客。
            </p>
          </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px;text-align:center;">
          <img src="../../../assets/img/yellow.png" alt="">
          <div class="bottom_br"></div>
        </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#CF9118"
      ></GovernmentServices>
    </div>
    <!-- 特色 -->
    <div class="winter" v-else>
      <div class="banner">
        <!-- <img
          width="100%"
          src="../../../assets/img/HeNan/banner.jpg"
          alt=""
          srcset=""
        /> -->
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in winterImgList" :key=index>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/HeNan/jianjie.png" alt="">
          <div class="desc">            
              河南乡位于大渡河南岸、县城东南部，与甘洛、石棉接壤，是古南丝绸之路必经地，还是红军长征途经地，具有厚重的历史文化底蕴和丰富的红色文化资源。近年来，河南乡加大对红色旅游景点建设的投入力度，全力打造“红军标语”等红色旅游目的地。            
          </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px;text-align:center;">
          <img src="../../../assets/img/red.png" alt="">
          <div class="bottom_br"></div>
        </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#920C05"
      ></GovernmentServices>
    </div>
  </div>
</template>
<script>


import GovernmentServices from "@/components/GovernmentServices/index";
import { getServerDate, matterQuery } from "@/api/map/index";
import { mixin } from "../../../mixin";
export default {
  name: "HeNan",
  components: {  GovernmentServices },
  data() {
    return {
      matterList: [], //事项列表
      villages: [], //乡镇列表
      name: "河南乡",
      keywords:'',
      autumnImgList: [
        {imgUrl:require('../../../assets/img/HeNan/banner.jpg')},
        {imgUrl:require('../../../assets/img/HeNan/autumn_banner.jpg')},
      ],
      winterImgList: [
        {imgUrl:require('../../../assets/img/HeNan/banner.jpg')},
        {imgUrl:require('../../../assets/img/HeNan/banner1.jpg')},
      ],
    };
  },
  mixins: [mixin],
  created() {
    this.$emit("public_header", false);
  },
  
};
</script>
<style scoped>
.banner {
  width: 100%;
  min-height: 215px;
  max-height: 427px;
}
.banner img{
  height: auto;
}
.con1 {
  position: relative;
  margin-top: -5px;
}
.con1 img{
  width: 100%;
}
.desc {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 29%;
  overflow-y: auto;
  text-indent: 1em;
  width: 64%;
  height: 65%;
}
.search-box {
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
/deep/.autumn .el-input__suffix{
  color: #E79E5F;
  font-size: 20px;
}
/deep/.winter .el-input__suffix{
  color: #DC6860;
  font-size: 20px;
}
/deep/.autumn .el-carousel__indicator.is-active button {
  background-color: #E79E5F;
}
/deep/.winter .el-carousel__indicator.is-active button {
  background-color: #DC6860;
}
/deep/.el-carousel__button {
  background-color: gray;
}
</style>