<template>
  <div v-loading="loading">
    <div class="banner">
      <p class="top-title" :title="matterName">{{ matterName }}</p>
    </div>

    <div class="cont">
      <div class="jbxx">
        <p class="jbxx-title">基本信息</p>
        <el-row class="list">
          <el-col :span="24" style="cursor: pointer">
            <div
              class="list-item"
              v-if="matterObj.matter"
              style="padding-top: 0"
            >
              <span>事项名称</span>
              <span>{{ matterObj.matter.name }}</span>
            </div>
            <div class="list-item" v-if="matterObj.matter">
              <span>办理部门</span>
              <span>{{ matterObj.matter.deptName }}</span>
            </div>
            <div class="list-item" v-if="matterObj.matter">
              <span>事项类型</span>
              <span>{{ matterObj.matter.itemName }}</span>
            </div>
            <div class="list-item" v-if="matterObj.matter">
              <span>服务类型</span>
              <span>{{
                matterObj.matter.serviceTypeId == 1
                  ? "个人服务"
                  : matterObj.matter.serviceTypeId == 2
                  ? "法人服务"
                  : matterObj.matter.serviceTypeId == "1,2" ||
                    matterObj.matter.serviceTypeId == "2,1"
                  ? "个人服务,法人服务"
                  : "法人服务,个人服务"
              }}</span>
            </div>
            <div class="list-item" v-if="matterObj.matter">
              <span>法定办结时限</span>
              <span v-if="matterObj.matter.statutoryCompletion==0">即办</span>
              <span v-else>{{ matterObj.matter.statutoryCompletion }}个工作日</span>
            </div>
            <div class="list-item" v-if="matterObj.matter">
              <span>承诺办结时限</span>
              <span v-if="matterObj.matter.promiseCompletion==0">即办</span>
              <span v-else>{{ matterObj.matter.promiseCompletion }}个工作日</span>
            </div>
            <div class="list-item">
              <span>办理时间</span>
              <span>{{ matterObj.handleTime }}</span>
            </div>
            <div class="list-item">
              <span>办理地点</span>
              <span>{{ matterObj.handleAddress }}</span>
            </div>
            <div class="list-item">
              <span>咨询电话</span>
              <span>{{ matterObj.consultingPhone }}</span>
            </div>
            <div class="list-item">
              <span>投诉电话</span>
              <span>{{ matterObj.supervisePhone }}</span>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="jbxx" v-if="matterObj.isQuestionaire">
        <p class="jbxx-title">情形选择</p>
        <guide :model="templateByMatterArr" @toGuide="toGuide" />
        <div class="flex-row" style="margin-top;: 10px">
          <el-button type="primary" @click="goSwitch">确 定</el-button>
        </div>
      </div>
      <div class="sqcl">
        <p class="sqcl-title">申请材料</p>
        <div
          v-if="
            matterObj.deptMatterMaterialList &&
            matterObj.deptMatterMaterialList.length > 0
          "
        >
          <!-- 所有申请材料展示 -->
          <el-table
            border
            :data="matterObj.deptMatterMaterialList"
            style="width: 100%"
          >
            <el-table-column label="材料">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.name
                  }}<span class="wtcl">{{
                    scope.row.originalType == 0
                      ? "原件"
                      : scope.row.originalType == 1
                      ? "复印件"
                      : "原件及复印件"
                  }}</span
                  ><span class="wtcl">{{ scope.row.copys }}份</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="checkMain"
              label="示例样表"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                <div>
                  <el-tag
                    type="success"
                    size="mini"
                    @click="exampleOpen(scope.$index)"
                    >材料样例</el-tag
                  >
                  <el-tag
                    style="margin-top: 10px"
                    size="mini"
                    @click="emptyDocumentOpen(scope.$index)"
                    >空白表单</el-tag
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <p
          v-if="
            matterObj.deptMatterMaterialList &&
            matterObj.deptMatterMaterialList.length == 0 &&
            !isQuestionaire
          "
          style="text-align: left"
        >
          无申请材料
        </p>
      </div>
      <div class="fdyj" v-if="matterObj.matter">
        <p class="fdyj-title">法定依据</p>
        <p class="tl" style="font-size: 15px; margin-bottom: 5px">
          {{ matterObj.matter.statutoryBasis }}
        </p>
      </div>
      <div class="sqtj" v-if="matterObj.matter">
        <p class="sqtj-title">申请条件</p>
        <p class="tl" style="font-size: 15px">
          {{ matterObj.matter.applicationConditions }}
        </p>
      </div>
      <div class="sfbz" v-if="matterObj.matter">
        <p class="sfbz-title">收费标准</p>
        <p class="tl">{{ matterObj.matter.chargingBasis }}</p>
      </div>
      <div
        class="flex-row"
        style="margin: 15px 20px 0 20px; justify-content: end"
      >
        <el-button size="mini" class="Map_guidance" @click="toMap(matterObj)"
          >地图指引</el-button
        >
        <!-- <el-button size="mini" class="Map_guidance" @click="toLink()"
          >办事指南</el-button
          matterObj.handleStatus == 0, 被退回，且审核还未通过
        > -->
        <el-button
          size="mini"
          class="online_application"
          v-if="
            matterObj.handleStatus !=0&&
            matterObj.isOnline == 0 &&
            matterObj.status == 0 &&
            (matterObj.matter.serviceTypeId == '1' ||
              matterObj.matter.serviceTypeId == '1,2')
          "
          @click="applyPop"
          >
          <span v-if='matterObj.handleType==1'>在线办理</span>
          <span v-else-if='matterObj.handleType==2'>在线预审</span>
          <span v-else>在线申请</span>
          </el-button
        >
      </div>
    </div>

    <el-dialog
      title="材料样例"
      :visible.sync="example"
      width="320px"
      append-to-body
    >
      <div class="imglist" v-if="deptMatterTemplates[indexApplicationMaterial]">
        <div
          v-for="(i, idx) in matterObj.deptMatterMaterialList[
            indexApplicationMaterial
          ].styles"
          :key="idx"
        >
          <div
            class="icon flex-row"
            style="cursor: pointer"
            v-for="(item, index) in i.deptFileExampleList"
            :key="index"
            @click="handleGenTable(item, matterObj.deptMatterMaterialList[
            indexApplicationMaterial
          ].name + '材料样例')"
          >
            <span>{{ item.name }}</span>
            <!-- <div class="flex-row" style="width: 65px">
              <i class="el-icon-download"></i>
              <span>下载</span>
            </div> -->
            <div class="flex-row" v-if='fileType.indexOf(item.fileType)!=-1'>
              <i class="el-icon-download"></i>
              <span>下载</span>
            </div>
            <div class="flex-row" v-else>
              <i class="el-icon-search"></i>
              <span>预览</span>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="example = false" size="mini"
          >确 定</el-button
        >
        <el-button @click="example = false" size="mini">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="空白文档"
      :visible.sync="emptyDocument"
      width="320px"
      append-to-body
    >
      <div class="imglist" v-if="deptMatterTemplates[indexApplicationMaterial]">
        <div
          v-for="(i, idx) in matterObj.deptMatterMaterialList[
            indexApplicationMaterial
          ].styles"
          :key="idx"
        >
          <div
            class="icon flex-row"
            style="cursor: pointer"
            v-for="(item, index) in i.deptFileTableList"
            :key="index"
            @click="handleGenTable(item, matterObj.deptMatterMaterialList[
            indexApplicationMaterial
          ].name + '空白文档')"
          >
            <span>{{ item.name }}</span>
            <div class="flex-row" v-if='fileType.indexOf(item.fileType)!=-1'>
              <i class="el-icon-download"></i>
              <span>下载</span>
            </div>
            <div class="flex-row" v-else>
              <i class="el-icon-search"></i>
              <span>预览</span>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="emptyDocument = false" size="mini"
          >确 定</el-button
        >
        <el-button @click="emptyDocument = false" size="mini">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="申请须知"
      :visible.sync="applyOpen"
      width="320px"
      :show-close="false"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      center
    >
      <div style="font-size: 13px">
        <p style="margin: 10px 0">申请须知：</p>
        <p style="text-indent: 2em; margin: 5px 0">
          1.申请人自愿申请网上办事，清楚由此可能产生的法律后果，同意本着诚实守信的原则提交所有申报材料并承担由此造成的法律后果。
        </p>
        <p style="text-indent: 2em; margin: 5px 0">
          2.申请人保证提交的申请材料和有关信息内容真实无误，否则造成不良后果的，由申请人承担。
        </p>
        <p style="text-indent: 2em; margin: 5px 0">
          3.申请人同意在本事项办理过程中采集、存储、使用本人的个人信息。
        </p>
        <p style="text-indent: 2em; margin: 5px 0">
          4.网上申请提交成功后，符合受理条件的，按照受理流程进行。有关重要信息将通过手机通知您。
        </p>
        <p style="text-indent: 2em; margin: 5px 0">
          5.申请人知晓网络服务存在中断风险，政务中心不做担保。在此情况下，若发生办件申请未能提交成功时，需由申请人重新提交。
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelApply">取 消</el-button>
        <el-button
          type="primary"
          @click="toOnlineApplication"
          :disabled="countdown > 0"
          >{{ countdown != 0 ? countdown + "秒可确定" : "确 定" }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="80%"
      >
        <div class="imgDialog">
          <img :src="imgUrl"/>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  matterInfo,
  getQuestionnaireMaterial,
  getOneLevelQuestionnaire,
} from "@/api/map/index";
import { download, refreshFileToken, downloadImg } from "@/api/user/index";
import guide from "@/components/rollUp/guide";
import * as req from "@/api/map/index";
import { appGlobalData } from "@/utils/index";
// import wx from "weixin-js-sdk";
export default {
  name: "ServiceGuide",
  components: { guide },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      deptMatterId: this.$route.params.deptMatterId || null, //事项ID
      matterName: this.$route.params.name || null, //事项名称
      matterObj: {}, //事项详情对象
      isQuestionaire: false, //是否有情形问卷
      deptMatterTemplates: [], //所有情形项
      rollUp: [],
      templateByMatterArr: [],
      example: false,
      emptyDocument: false,
      indexApplicationMaterial: 0,
      applyOpen: false,
      countdown: 5, // 设置倒计时时间（单位：秒）
      timer: null,
      showTis: false,
      imgType: ["png","jpg","jpeg","JPG","PNG","JPEG"],
      fileType: ['doc', 'docx', 'xlsx', 'xls', 'ppt', 'pptx', 'pdf'],
      imgUrl: '',
    };
  },

  mounted() {
    this.getDetail();
    this.$forceUpdate();
    let isServiceGuideAuth = localStorage.getItem("isServiceGuideAuth");
    if (isServiceGuideAuth && isServiceGuideAuth === 'true') {
      localStorage.setItem("isServiceGuideAuth", '');
      this.getUserInfo();
    }
  },
  methods: {
    // 人脸识别回调
    async getUserInfo() {
      let that = this;
      req
        .faceCallback({ sessionId: that.$store.state.sessionId })
        .then(async (res) => {
          if (res.data.code == 200) {
            that.$store.commit("SET_TOKEN", res.data.token.access_token);
            that.$store.commit("SET_NAME", res.data.userInfo.sysUser.name);
            that.$store.commit(
              "SET_FACEAUTH",
              res.data.userInfo.sysUser.faceAuth
            );
            const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
            loginInfo.token = res.data.token.access_token;
            localStorage.setItem("loginInfo", JSON.stringify(loginInfo));
            if (localStorage.getItem("userInfo")) {
              const userInfo = JSON.parse(localStorage.getItem("userInfo"));
              userInfo.faceAuth = res.data.userInfo.sysUser.faceAuth;
              localStorage.setItem("userInfo", JSON.stringify(userInfo));
            } else {
              let userInfo = {
                name: res.data.userInfo.sysUser.name,
                phonenumber: res.data.userInfo.sysUser.phonenumber,
                idCard: res.data.userInfo.sysUser.idCard,
                faceAuth: res.data.userInfo.sysUser.faceAuth,
              };
              localStorage.setItem("userInfo", JSON.stringify(userInfo));
            }
            that.$forceUpdate();
          } else {
            that.$alert(
              `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
              "",
              {
                dangerouslyUseHTMLString: true,
                center: true,
                showConfirmButton: false,
              }
            );
          }
        });
    },
    applyPop() {
      if (
        localStorage.getItem("loginInfo") &&
        JSON.parse(localStorage.getItem("loginInfo")).token
      ) {
        if (
          localStorage.getItem("userInfo") &&
          JSON.parse(localStorage.getItem("userInfo")).faceAuth == 0
        ) {
          // this.$confirm("未人脸认证，请进行人脸认证", "系统提示", {
          this.$confirm("根据网上政务服务相关规定线上申请需进行人脸认证，请进行人脸识别", "系统提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            // location.href = "#/faceAuthentication";
            // localStorage.setItem("redirectUrl", 'ServiceGuide')
            this.$router.push({
              name: "faceAuthentication",
              params: { type: 'ServiceGuide' },
            });
          });
        } else {
          this.applyOpen = true;
          this.startCountdown();
        }
      } else {
        this.$confirm("未实名认证，请进行实名认证", "系统提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          location.href = "#/register";
        });
      }
    },
    cancelApply() {
      this.applyOpen = false;
      clearTimeout(this.timer);
      this.countdown = 5; // 重置倒计时时间
    },
    startCountdown() {
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    // 事项详情
    getDetail() {
      // 事项详情  post  参数 matterId  事项id
      let params = {
        deptMatterId: this.deptMatterId,
      };
      this.loading = true;
      matterInfo({
        method: "/mobile/matterInfo",
        body: JSON.stringify(params),
      }).then((res) => {
        if (res.data.code == 200) {
          this.matterObj = res.data.data;
          this.isQuestionaire = res.data.data.isQuestionaire;
          if (!this.matterObj.isQuestionaire) {
            this.deptMatterTemplates = this.matterObj.deptMatterMaterialList;
          } else {
            let obj = {
              deptMatterId: this.deptMatterId,
              parentId: 0,
            };
            getOneLevelQuestionnaire({
              method: "/mobile/getOneLevelQuestionnaire",
              body: JSON.stringify(obj),
            }).then((r) => {
              this.templateByMatterArr = r.data.data;
            });
          }
          this.loading = false;
        } else {
          this.$alert(
            `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
            "",
            {
              dangerouslyUseHTMLString: true,
              center: true,
              showConfirmButton: false,
            }
          );
          this.loading = false;
        }
      });
    },
    // 地图指引--跳转到地图页面
    toMap(matterObj) {
      let obj = {
        deptMatterId: matterObj.deptMatterId,
        name: matterObj.superMatterName,
        addressName: matterObj.hanyuanMapList[0].name,
      };
      sessionStorage.setItem("matterDetails", JSON.stringify(obj));
      this.$router.push({
        name: "map",
        params: obj,
      });
    },
    // 办事指南，第三方跳转测试
    toLink () {
      location.href = 'http://www.hanyuan.gov.cn/old_index.html';
    },
    // 到在线申请
    toOnlineApplication() {
      this.applyOpen = false;
      clearTimeout(this.timer);
      this.countdown = 5; // 重置倒计时时间
      let obj = {
        deptMatterId: this.deptMatterId,
        isQuestionaire: this.isQuestionaire,
        name: this.matterName,
        templateByMatterArr: this.templateByMatterArr,
      };
      this.$router.push({
        name: "OnlineApplication",
        params: obj,
      });
      sessionStorage.setItem("matterDetails", JSON.stringify(obj));
    },
    // 材料样例
    exampleOpen(index) {
      this.indexApplicationMaterial = index;
      if (this.matterObj.deptMatterMaterialList[index].styles.length != 0) {
        if (
          this.matterObj.deptMatterMaterialList[index].styles[0]
            .deptFileExampleList.length != 0
        ) {
          this.example = true;
        } else {
          this.$message({
            message: "无材料样例",
            type: "warning",
          });
        }
      } else {
        this.$message({
          message: "无材料样例",
          type: "warning",
        });
      }
    },
    // 空白表单
    emptyDocumentOpen(index) {
      this.indexApplicationMaterial = index;
      if (this.matterObj.deptMatterMaterialList[index].styles.length != 0) {
        if (
          this.matterObj.deptMatterMaterialList[index].styles[0]
            .deptFileTableList.length != 0
        ) {
          this.emptyDocument = true;
        } else {
          this.$message({
            message: "无空白表单",
            type: "warning",
          });
        }
      } else {
        this.$message({
          message: "无空白表单",
          type: "warning",
        });
      }
    },
    // 情形引导
    toGuide(val) {
      this.rollUp = val;
    },
    // 情形引导提交
    goSwitch() {
      let params = {
        deptMatterId: this.deptMatterId,
        agencySingleDeptQuestionaireSelectSaveList: this.rollUp,
      };
      if (this.rollUp.length != 0) {
        getQuestionnaireMaterial({
          method: "/mobile/getQuestionnaireMaterial",
          body: JSON.stringify(params),
        }).then((res) => {
          this.$message({
            message: `${res.data.msg}`,
            type: "success",
          });
          this.matterObj.deptMatterMaterialList = res.data.data;
          this.deptMatterTemplates = this.matterObj.deptMatterMaterialList;
        });
      } else {
        this.$message({
          message: "请选择后确认",
          type: "warning",
        });
      }
    },
    isiOSDevice() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    checkUrl() {
      let ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true; // '微信浏览器'
      } else {
        return false; // 其他浏览器
      }
    },
    // 预览下载
    handleGenTable(file, title) {
      // refreshFileToken(file).then( res => {
      //   console.log('file', res)
      //   if (res.data.code == 200) {
      //     let link = document.createElement("a");
      //     link.style.display = "none";
      //     link.href = appGlobalData.link + res.data.data.httpUrl + '?' + res.data.data.token;
      //     console.log('下载路径', link.href)
      //     let filename = file.name;
      //     link.setAttribute("download", filename);
      //     document.body.appendChild(link);
      //     link.click();
      //   } else {
      //     this.$message({
      //       message: res.data.msg,
      //       type: "warning",
      //     });
      //   }
      // })
      if (this.imgType.indexOf(file.fileType) != -1) {
        downloadImg({
          filePath: file.filePath,
          fileName: file.name,
        }).then((res) => {
          this.imgUrl = 'data:'+res.data.data.mimeType+';base64,' + res.data.data.base64;
          this.dialogVisible = true;
        });
      } else if (this.fileType.indexOf(file.fileType) != -1 || this.isiOSDevice() || !this.checkUrl()) { // 苹果手机支持所有
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = appGlobalData.link + `prod-api/transfer/common/download?filePath=${file.filePath}&fileName=${file.name}`
        console.log('下载路径', link.href)
        let filename = file.name;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link) // 下载完成移除元素
      } else {
        // 微信无法下载
        this.$router.push({
            name: "DownloadPage",
            query: {
              name:file.name,
              filePath: file.filePath,
              title: title,
              link: appGlobalData.link
            },
          });

      }

    },
  },
  created() {
    if (sessionStorage.getItem("matterDetails")) {
      this.deptMatterId = JSON.parse(
        sessionStorage.getItem("matterDetails")
      ).deptMatterId;
      this.matterName = JSON.parse(
        sessionStorage.getItem("matterDetails")
      ).name;
    }
    this.$emit("public_header", true);
    this.$emit("public_footer", true);
  },
};
</script>
<style scoped>
.layer{
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rbga(0, 0, 0, 0.2);
}
.imgDialog{
  width: 100%;
  height: 300px;
  position: relative;
}
.imgDialog img{
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.flex-row {
  display: flex;
  align-items: center;
}
.banner {
  width: 100%;
  height: 121px;
  background: url("../../assets/img/titleBG.jpg") no-repeat;
  background-size: 100vw 17vh;
  display: flex;
  align-items: center;
}

.list-item {
  text-align: left;
  padding: 15px 0;
  border-bottom: 1px solid #dddddd;
  display: flex;
  padding-left: 0;
  align-items: center;
  justify-content: space-between;
}

.list-item span {
  font-size: 16px;
}
.list-item span:nth-child(2) {
  font-size: 14px;
  color: #999;
  width: 65%;
  text-align: right;
}
.wtcl {
  background-color: rgb(231, 231, 231);
  padding: 3px 5px;
  margin-left: 10px;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  font-size: 12px;
}

.top-title {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  text-align: left;
  padding: 0 20px 0 30px;
}

.Map_guidance {
  background: rgb(100, 195, 250) !important;
  color: #fff;
  box-shadow: 1px 1px 3px #333;
  border: 1px solid rgb(100, 195, 250) !important;
}
.online_application {
  background: rgb(100, 195, 250) !important;
  color: #fff;
  box-shadow: 1px 1px 3px #333;
  border: 1px solid rgb(100, 195, 250) !important;
}
.Map_guidance:hover {
  color: #606266;
  background: #fff;
  border: 1px solid #dcdfe6;
}
.online_application:hover {
  color: #606266;
  background: #fff;
  border: 1px solid #dcdfe6;
}

.cont {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.cont-left {
  border-right: 1px solid #dadada;
  height: 200px;
  float: left;
}

.cont-left li {
  padding: 10px;
}

.active {
  border-right: 2px solid #3ea6ed;
}

#cont-right {
  width: 90%;
  float: right;
  margin-bottom: 60px;
}

.jbxx,
.sqcl,
.fdyj,
.sqtj,
.sfbz {
  padding: 0 20px;
}

.jbxx .jbxx-title,
.sqcl .sqcl-title,
.fdyj .fdyj-title,
.fdyj .fdyj-title,
.sqtj .sqtj-title,
.sfbz .sfbz-title {
  padding: 20px 0 15px 0;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  width: 100px;
}

.sqcl .qxxz-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
}

.sqcl .sfdb {
  margin: 10px 0;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
}

.tl {
  text-align: left;
}

.qx-btns {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.qx-btn {
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 0 !important;
}

.tabal-title-bg {
  background-color: #f5f6f8;
}

.imglist {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.icon {
  margin-top: 10px;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}
</style>