<template>
  <div class="HanYuan" v-loading="loading">
    <!--春季：1-3月-->
    <div class="spring">
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in imglist" :key=index>
            <img width="100%" :src="item.imgUrl" ref="imgH" @load="imgLoad" alt="" srcset="">
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div class="con1">
        <img src="../../../assets/img/HanYuan/jianjie.png" alt="">
        <div class="desc">
          汉源花椒历史悠久，唐代列为贡品，故名“贡椒”。以其色泽丹红，粒大油重，芳香浓郁、醇麻爽口，畅销省内外，1984年荣获省优质产品称号，深受消费者欢迎...
        </div>
      </div> -->
      <!-- 事项公开标题 -->
      <div style="margin-top: 30px;text-align:center;">
          <img src="../../../assets/img/dark blue.png" alt="">
          <div class="bottom_br"></div>
        </div>
      <!-- 事项公开搜索 -->
      <div class="search-box">
        <el-input
          placeholder="搜索服务事项"
          suffix-icon="el-icon-search"
          v-model="keywords" @focus="toSearchView"
        >
        </el-input>
      </div>
      <!-- 事项公开内容组件 -->
      <GovernmentServices
        :mapId="mapId"
        :name="name"
        color="#384890"
      ></GovernmentServices>
    </div>
  </div>
</template>
<script>

import GovernmentServices from "@/components/GovernmentServices/index";
import { getServerDate, matterQuery, getVillages } from "@/api/map/index";
import { mixin } from "../../../mixin";
export default {
  name: "HanYuan",
  components: {  GovernmentServices },
  data() {
    return {
      mapId:[],
      matterList: [], //事项列表
      villages: [], //乡镇列表
      name: "县本级",
      keywords:'', 
      imglist:[
        {imgUrl:require('../../../assets/img/HanYuan/banner1.jpg')},
        {imgUrl:require('../../../assets/img/HanYuan/banner2.jpg')},
      ]
    };
  },
  mixins: [mixin],
  created() {
    this.$emit("public_header", false);
    this.getMapId();
  },
  methods: {
    getMapId() {
      // type为2代表县本级
      let obj={method:'/mobile/getVillages',body:JSON.stringify({type:2})}
      getVillages(obj).then((res)=>{
        if(res.data.code==200){
          let data = res.data.data && res.data.data[0] ? res.data.data[0] : {}
          let hanYuanMapMobileVOs = data.hanYuanMapMobileVOs ? data.hanYuanMapMobileVOs[0] : {}
          this.mapId = [hanYuanMapMobileVOs.id] || [];
          sessionStorage.setItem("mapIds", JSON.stringify(this.mapId))
        }else{
          this.$message.error(res.data.msg)
        }
      })
    }
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  min-height: 215px;
  max-height: 427px;
}
.banner img{
  height: auto;
}
.con1 {
  position: relative;
  margin-top: -5px;
}
.con1 img{
  width: 100%;
}
.desc {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 15%;
  left: 29%;
  overflow-y: auto;
  text-indent: 1em;
  width: 64%;
  height: 65%;
}
/deep/.el-carousel__indicator.is-active button {
  background-color: #1296db;
}
/deep/.el-carousel__button {
  background-color: gray;
}
/deep/.el-input__icon{
  font-size: 20px;
  color: #3664A0;
}
/* /deep/.el-input__inner{
  border: none;
} */
.search-box {
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
</style>