<template>
  <div class="resetPwd">
    <div class="topTitle">重置密码</div>
    <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="95px"
      class="demo-ruleForm"
    >
      <p class="grayTips">当前登录的手机号为188****8888，请获取验证码验证。</p>
      <el-form-item label="验证码" prop="verificationCode">
        <el-input
          v-model.number="ruleForm.verificationCode"
          placeholder="请填写验证码"
        >
          <el-button size="mini" type="primary" slot="suffix" @click="getCode"
            >获取验证码</el-button
          >
        </el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="password">
        <el-input
          type="password"
          v-model="ruleForm.password"
          autocomplete="off"
          placeholder="请输入新密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input
          type="password"
          v-model="ruleForm.checkPass"
          autocomplete="off"
          placeholder="请再次输入密码"
        ></el-input>
      </el-form-item>
      <p class="blackTips">
        密码长度为8-15位，需包含特殊字符（如：%、&、#等），且需包含大写字母、小写字母、数字中的两种或以上，不允许有空格。
      </p>
    </el-form>
    <el-button
      style="width: 100%"
      type="primary"
      @click="resetPwd('ruleForm')"
      >重置密码</el-button
    >
  </div>
</template>
  
<script>
import * as req from "@/api/user/index";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        var passwordRegex =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[%&#])[A-Za-z\d%&$#]{8,15}$/;
        // 检查密码是否符合规则
        if(!passwordRegex.test(value)){
          callback(new Error("密码格式错误！"));
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        password: "",
        checkPass: "",
        verificationCode: "",
      },
      rules: {
        password: [{ validator: validatePass,required: true, trigger: "blur" }],
        checkPass: [{ validator: validatePass2,required: true, trigger: "blur" }],
        verificationCode: [
          { required: true, trigger: "blur", message: "请输入您收到的验证码" },
        ],
      },
    };
  },
  methods: {
    // 提交注册
    resetPwd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 获取验证码
    getCode() {
      req.verify({ phoneNumber: this.ruleForm.phoneNumber }).then((res) => {
        console.log(res, "验证码");
      });
    },
  },
};
</script>
  
  
  <style scoped>
.resetPwd {
  margin: 20px;
}
form {
  border: none !important;
}
/deep/.el-form-item__label {
  text-align: left;
}
/deep/.el-form-item {
  border-bottom: 1px solid #c2c2c2;
}
/deep/.el-input__inner {
  border: none;
}
</style>