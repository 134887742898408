<template>
  <div class="mapcontainer">
    <!-- 地图容器 -->
    <div id="mapBox"></div>
  </div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
// 自定义图标（缩放有点位偏移问题）
import zwfwIcon from "../../assets/img/zwfwIcon.png";
import jyzyIcon from "../../assets/img/jyzyIcon.png";
import ylwsIcon from "../../assets/img/ylwsIcon.png";
import ylfwIcon from "../../assets/img/ylfwIcon.png";
window._AMapSecurityConfig = {
  securityJsCode: "cc18b4e487c33921dc2b0a5ddd71f80d",
};
import { queryDeptMatterRegion, weixinKey } from "@/api/map/index";
import wx from "weixin-js-sdk";
import { appGlobalData } from "@/utils/index";
export default {
  props: {
    mapData: [],
    type: 0,
    serviceMapList: [],
    drawer: false, //点击地图标记点时的弹窗
  },
  data() {
    return {
      map: null,
      // infoWindow: null, //信息窗
      winInfo: "",
      acitveObj: {}, //当前点击点位信息
    };
  },
  watch: {
    mapData: {
      handler(newVal, oldVal) {
        this.initMap();
      },
    },
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: "93dd0036e71008f9dbfccd3e3c0c50cb", // 申请好的Web端开发者Key，首次调用 load 时必填
        // version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("mapBox", {
            //设置地图容器id
            viewMode: "2D", //是否为3D地图模式
            zoom: 13, //初始化地图级别
            center: [102.650782, 29.344236], //初始化地图中心点位置
          });
          for (let i = 0; i < this.mapData.length; i++) {
            var marker = new AMap.Marker({
              position: this.mapData[i].position, //不同标记点的经纬度
              map: this.map,
              extData: this.mapData[i],
              animation: "AMAP_ANIMATION_DROP",
            });
            marker.content = ` 
            <p style="padding:10px;font-weight:bold;"> ${
              this.mapData[i].name
            } </p>
            <div class="detail_item"> <img src="${require("../../assets/img/address_icon.png")}" alt="" /> ${
              this.mapData[i].address
            }</div>
            <div class="detail_item" style="${
              this.mapData[i].workTime ? "display:inherit" : "display:none"
            };margin-bottom: 10px;"> <img src="${require("../../assets/img/workTime_icon.png")}" alt="" /> 办公时间：${
              this.mapData[i].workTime
            }</div>
            <div class="detail_item"> <img src="${require("../../assets/img/phone_icon.png")}" alt="" /> 咨询电话： ${
              this.mapData[i].phoneNumbe ? this.mapData[i].phoneNumbe : "暂无"
            }</div>
            <div style="display:flex;align-items: center; justify-content: end;"><div style="margin-right:10px;"><img width="25px" src="${require("../../assets/img/daohang_icon.png")}" onclick="openLocation()" /></div><button class="mapBtn" onclick="toDisclosure()" >事项公开</button></div>
            `;
            marker.on("click", this.markerClick);
            window.toDisclosure = () => {
              this.toDisclosure(this.mapData[i]);
            };
            window.openLocation = () => {
              this.openLocation(this.mapData[i]);
            };
          }

          // 将创建的点标记添加到已有的地图实例：
          this.map.add([marker]);
          this.map.setFitView(null, false);
          // 设置窗体内容
          var infoWindow = new AMap.InfoWindow({
            // isCustom: true,  //是否自定义窗体。设为true时，信息窗体外框及内容完全按照content所设的值添加（默认为false，即在系统默认的信息窗体外框中显示content内容）--为ture时信息窗没有指向标点的箭头了
            content: ` <p style="font-weight:bold;padding:10px;"> ${
              this.mapData[0].name
            } </p>
              <div class="detail_item">
                <img src="${require("../../assets/img/address_icon.png")}" />
                地址：${
                  this.mapData[0].address ? this.mapData[0].address : "暂无"
                }</div>
              <div class="detail_item" style="${
                this.mapData[0].workTime ? "display:inherit" : "display:none"
              }">
              <img src="${require("../../assets/img/workTime_icon.png")}" />
              办公时间：${this.mapData[0].workTime}</div>
              <div class="detail_item">
                <img src="${require("../../assets/img/phone_icon.png")}" />
                咨询电话：${
                  this.mapData[0].phoneNumbe
                    ? this.mapData[0].phoneNumbe
                    : "暂无"
                }</div>  
                <div style="display:flex;align-items: center; justify-content: end;"><div style="margin-right:10px;"><img width="25px" src="${require("../../assets/img/daohang_icon.png")}" onclick="openLocation()" /></div> <button class="mapBtn"  onclick="toDisclosure()" >事项公开</button></div>            
              `,
            offset: new AMap.Pixel(15, -35),
            autoMove: true, //是否自动调整窗体到视野内（当信息窗体超出视野范围时，通过该属性设置是否自动平移地图，使信息窗体完全显示）
            avoid: [100, 20, 150, 20], //autoMove 为 true 时，自动平移到视野内后的上右下左的避让宽度。默认值： [20, 20, 20, 20]
            closeWhenClickMap: true, //控制是否在鼠标点击地图后关闭信息窗体，默认false，鼠标点击地图后不关闭信息窗体
            anchor: "bottom-center",
          });
          window.toDisclosure = () => {
            this.toDisclosure(this.mapData[0]);
          };
          window.openLocation = () => {
            this.openLocation(this.mapData[0]);
          };
          infoWindow.open(this.map, this.mapData[0].position);

          // 页面控件
          // this.map.plugin(["AMap.ControlBar", "AMap.ToolBar"], () => {
          //   var controlBar = new AMap.ControlBar({ position: 'RT' });
          //   var toolBar = new AMap.ToolBar({ position: { top: '100px', right: '25px' } });
          //   this.map.addControl(controlBar);
          //   this.map.addControl(toolBar);
          // });
        })
        .catch((e) => {
          // console.log(e);
        });
    },
    // 标点点击事件
    async markerClick(e) {
      // console.log(e, e.target, "/");
      // console.log(e.pos,'dianw')
      // console.log(this.map)
      this.acitveObj = e.target.w.extData;
      window.toDisclosure = () => {
        this.toDisclosure(this.acitveObj);
      };
      this.winInfo = e.target.content;
      // await this.queryHaveDisclosure(this.acitveObj.id, this.winInfo);
      // 设置窗体内容
      var infoWindow = new AMap.InfoWindow({
        // isCustom: true,  //是否自定义窗体。设为true时，信息窗体外框及内容完全按照content所设的值添加（默认为false，即在系统默认的信息窗体外框中显示content内容）--为ture时信息窗没有指向标点的箭头了
        content: this.winInfo,
        autoMove: true, //是否自动调整窗体到视野内（当信息窗体超出视野范围时，通过该属性设置是否自动平移地图，使信息窗体完全显示）
        avoid: [100, 20, 150, 20], //autoMove 为 true 时，自动平移到视野内后的上右下左的避让宽度。默认值： [20, 20, 20, 20]
        offset: new AMap.Pixel(15, -35),
        closeWhenClickMap: true, //控制是否在鼠标点击地图后关闭信息窗体，默认false，鼠标点击地图后不关闭信息窗体
        anchor: "bottom-center",
      });
      // console.log(e.pos)
      // this.map.setZoomAndCenter(13, [e.pos]);
      infoWindow.open(this.map, [
        e.target.w.position.lng,
        e.target.w.position.lat,
      ]);
    },
    // 查询是否有事项公开
    queryHaveDisclosure(id) {
      let obj = {
        method: "/mobile/queryDeptMatterRegion",
        body: JSON.stringify({ id }),
      };
      queryDeptMatterRegion(obj).then((res) => {
        if (res.data.code == 200) {
          this.drawer == true
            ? this.$emit("showMarkDrawer", false)
            : this.$emit("showMarkDrawer", true);
          this.$emit("closeDrawerDetail", false);
          this.acitveObj.isItem = res.data.data.isItem;
        } else {
          this.$alert(
            `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
            "",
            {
              dangerouslyUseHTMLString: true,
              center: true,
              showConfirmButton: false,
            }
          );
        }
      });
    },
    // 事项公开
    toDisclosure(item) {
      let that = this;
      let obj = {
        method: "/mobile/queryDeptMatterRegion",
        body: JSON.stringify({ id: item.id }),
      };
      queryDeptMatterRegion(obj).then((res) => {
        if (res.data.code == 200) {
          if (res.data.data.isItem == "1") {
            sessionStorage.setItem("topTitle", res.data.data.regionName);
            let i = that.serviceMapList.findIndex(
              (el) => el.name == res.data.data.regionName.slice(0, 2)
            );
            if (i != -1) {
              that.$router.push({
                name: that.serviceMapList[i].linkName,
              });
            }
          } else {
            this.$message({
              message: "该点位暂无事项公开！",
              type: "warning",
            });
          }
        } else {
          that.$message.error(res.data.msg);
        }
      });
    },
    // 导航
    openLocation(item) {
      var signLink = "";
      var ua = navigator.userAgent.toLowerCase();
      // if (/iphone|ipad|ipod/.test(ua)) {
      //   // signLink = "https://hanyuan.fengrenkeji.cn/";
      //   signLink = appGlobalData.link;
      // } else {
      //   signLink = window.location.href.split("#")[0];
      // }
      signLink = appGlobalData.link;
      weixinKey(signLink).then((res) => {
        if (res.data.code == 200) {
          // 配置微信 JSSDK
          wx.config({
            // 配置你的公众号 appId
            // appId: "wxd5baa829d8869161",
            appId: appGlobalData.appId,
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            debug: false,
            jsApiList: ["checkJsApi", "openLocation"],
          });
          wx.ready(() => {
            console.log('openLocationReady');
            wx.checkJsApi({
              jsApiList: ["openLocation"],
              success: (rest) => {
                //打开指定位置
                console.log('success')
                wx.openLocation({
                  latitude: parseFloat(item.latitude),
                  longitude: parseFloat(item.longitude),
                  name: item.name,
                  address: "", // 地址详情说明
                  scale: 18, // 地图缩放级别,整型值,范围从1~28。默认为最大
                  infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
                  success() {
                    console.log('地图打开成功');
                  },
                  fail(err) {
                      console.error('地图打开失败', err);
                  }
                });
              },
              fail: (errMsg) => {
                console.log('fail', errMsg);
                this.$alert(
                   `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${errMsg}</div>`,
                 "",
                {
                   dangerouslyUseHTMLString: true,
                   center: true,
                   showConfirmButton: false,
                }
              );
              }
            });
          });
        } else {
          this.$alert(
            `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
            "",
            {
              dangerouslyUseHTMLString: true,
              center: true,
              showConfirmButton: false,
            }
          );
        }
      });
    },
    // 关闭点位弹窗
    closeDelog() {
      this.$emit("showMarkDrawer", false);
    },
    // 不同地图类型标记点图标不同（自定义标点图标有缩放点位偏移问题--未使用）
    filterIcon(type) {
      switch (type) {
        case 0:
          // return '../../assets/img/zwfwIcon.png';
          return zwfwIcon;
        case 1:
          // return "../../assets/img/jyzyIcon.png";
          return jyzyIcon;
        case 3:
          // return "../../assets/img/ylwsIcon.png";
          return ylwsIcon;
        case 2:
          // return "../../assets/img/ylfwIcon.png";
          return ylfwIcon;
      }
    },
  },
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap();
  },
};
</script>

<style scoped>
#mapBox {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 932px;
  overflow-y: hidden;
  /* min-height: 680px;
  max-height: 932px; */
}
/deep/.el-drawer {
  border-radius: 10px 10px 0 0;
  border: 1px solid;
}
.dlog {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  border-radius: 5px 5px 0 0;
  width: 100%;
  box-sizing: border-box;
}
</style>
