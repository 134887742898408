import Vue from 'vue'
// import Vant from 'vant'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex'
import '@/icons'
import 'babel-polyfill' //兼容IE
import wx from 'weixin-js-sdk';
import 'vant/lib/index.css'
import '@/utils/vant.js'
import { parseTime } from "@/utils/ruoyi";
Vue.config.productionTip = false
//引入需要的组件
// Vue.use(Vant);
Vue.use(Vuex);
Vue.use(ElementUI);
import VueLazyLoad from 'vue-lazyload'
 
// 设置默认选项
const options = {
    // loading: require('@/assets/loading.gif'), // 加载时显示的图片路径
    // error: require('./assets/img/error.png'),
}
 
Vue.use(VueLazyLoad, options)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
Vue.prototype.$wx = wx;
Vue.prototype.parseTime = parseTime
router.beforeEach((to, from, next) => {
  let token = '';
  if(localStorage.getItem('loginInfo')){
     token = JSON.parse(localStorage.getItem('loginInfo')).token
  }else{
    token = ''
  }  
  // console.log(to,from,next,'>>>>>>>')
  if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
    if (!token) { //未登录
      next({
        path: '/'
      })
    } else {  
      next();
    }
  }else {
    next()
  }
})