<template>
  <div v-loading="loading">
    <div class="banner">
      <p class="top-title">{{ lifeCycleName }}</p>
    </div>
    <div class="cont">
      <div class="searBox">
        <el-input
          placeholder="请输入你要查找的事项"
          v-model.trim="queryParams.name"
          v-focus
          style="width: 100%"
          @keyup.enter.native="getLifeCycle"
        >
          <i
            slot="suffix"
            class="el-input__icon el-icon-search"
            @click="getLifeCycle"
          ></i>
        </el-input>
      </div>
      <el-row class="list">
        <el-col
          :span="24"
          v-for="(item, index) in lifeCycleList"
          :key="index"
          style="cursor: pointer"
        >
          <div
            class="list-item"
            @click="toDetail(item.deptMatterId, item.name)"
          >
            <div style="display: flex">
              <!-- <span></span> -->
              {{ item.name }}
            </div>
            <!-- <i class="el-icon-d-arrow-right"></i> -->
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- <div v-show="total==0">该生命周期暂无事项！</div> -->
    <el-empty
      v-show="total == 0 && !queryParams.deptId"
      description="该生命周期暂无事项！"
    ></el-empty>
    <el-empty
      v-show="total == 0 && queryParams.deptId"
      description="该部门暂无事项！"
    ></el-empty>
    <el-pagination
      v-show="total > 0"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryParams.pageNum"
      :page-size="queryParams.pageSize"
      small
      layout="prev, pager, next, jumper"
      :total="total"
      style="margin-top: 20px"
    >
    </el-pagination>
  </div>
</template>
<script>
import { matterQuery } from "@/api/map/index";
export default {
  name: "",

  data() {
    return {
      loading: false,
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        // lifeCycleId: this.$route.query.lifeCycleId || null,
        // serviceTypeId:this.$route.query.serviceTypeId || null,
        lifeCycleId:
          this.$route.params.lifeCycleId ||
          this.$route.query.lifeCycleId ||
          null, //生命周期ID
        serviceTypeId:
          this.$route.params.serviceTypeId ||
          this.$route.query.serviceTypeId ||
          null, //服务类型：个人服务、法人服务
        deptId: this.$route.params.deptId || null,
        // mapIds: this.$route.params.id,
        mapIds: JSON.parse(sessionStorage.getItem("mapIds") || '[]'),
        name: "",
      },
      // lifeCycleName:this.$route.query.name ||null,
      lifeCycleName: this.$route.params.name || this.$route.query.name || null,
      lifeCycleList: [], //生命周期数据
    };
  },

  mounted() {
    if (sessionStorage.getItem("lifeCycleDetails")) {
      this.queryParams.deptId = JSON.parse(
        sessionStorage.getItem("lifeCycleDetails")
      ).deptId;
      // this.queryParams.mapIds = JSON.parse(
      //   sessionStorage.getItem("lifeCycleDetails")
      // ).id;
    }
    // if (sessionStorage.getItem("mapIds")) {
    //   this.queryParams.mapIds = JSON.parse(sessionStorage.getItem("mapIds"));
    // }
    // if (sessionStorage.getItem("deptObj")) {
    //   this.queryParams.mapIds = JSON.parse(sessionStorage.getItem("deptObj")).id;
    // }
    // console.log(this.queryParams.deptId)
    // if (this.queryParams.deptId != "") {
    //   this.queryParams.mapIds = [];
    // }
    this.getLifeCycle();
    this.$forceUpdate();
  },

  methods: {
    // 获取生命周期
    getLifeCycle() {
      if (sessionStorage.getItem("mapIds")) {
        this.queryParams.mapIds = JSON.parse(sessionStorage.getItem("mapIds"));
      }
      // let params={lifeCycleId:this.lifeCycleId}
      if (this.queryParams.deptId) {
        //部门ID存在 则点位ID清空
        this.queryParams.id = "";
      }
      this.loading = true;
      let queryParams = JSON.parse(JSON.stringify(this.queryParams));
      queryParams.pageNum = queryParams.pageNum - 1;
      let obj = {
        method: "/mobile/matterQuery",
        body: JSON.stringify(queryParams),
      };
      matterQuery(obj).then((res) => {
        // console.log(res, '生命周期详情？')
        if (res.data.code == 200) {
          this.lifeCycleList = res.data.rows;
          this.total = res.data.total;
          this.loading = false;
        } else {
          this.$alert(
            `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
            "",
            {
              dangerouslyUseHTMLString: true,
              center: true,
              showConfirmButton: false,
            }
          );
          this.loading = false;
        }
      });
    },
    // 事项详情页
    toDetail(deptMatterId, name) {
      // this.$router.push({
      //   name: "ServiceGuide",
      //   query: { matterId: id,name }
      // });
      let obj = { deptMatterId: deptMatterId, name };
      this.$router.push({
        name: "ServiceGuide",
        params: obj,
      });
      sessionStorage.setItem("matterDetails", JSON.stringify(obj));
      this.$forceUpdate();
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.queryParams.pageSize = val;
      this.getLifeCycle();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.queryParams.pageNum = val;
      this.getLifeCycle();
    },
  },
  created() {
    if (sessionStorage.getItem("lifeCycleDetails")) {
      this.queryParams.lifeCycleId = JSON.parse(
        sessionStorage.getItem("lifeCycleDetails")
      ).lifeCycleId;
      this.queryParams.serviceTypeId = JSON.parse(
        sessionStorage.getItem("lifeCycleDetails")
      ).serviceTypeId;
      this.lifeCycleName = JSON.parse(
        sessionStorage.getItem("lifeCycleDetails")
      ).name;
    }
    this.$emit("public_header", true);
    this.$emit("public_footer", true);
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  height: 121px;
  background: url("../../assets/img/titleBG.jpg") no-repeat;
  background-size: 100vw 17vh;
  display: flex;
  align-items: center;
}

.top-title {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  text-align: left;
  padding: 0 20px 0 30px;
}

.cont {
  width: 100%;
  margin: 0 auto;
}
.searBox {
  width: 90%;
  box-shadow: 2px 2px 10px #ccc;
  border-radius: 10%;
  margin: 20px auto;
}

.list {
  padding: 0 20px;
}

.list-item {
  text-align: left;
  padding: 15px 0;
  border-bottom: 1px solid #dddddd;
  display: flex;
  padding-left: 0;
  align-items: center;
  justify-content: space-between;
}

.list-item span {
  width: 5px;
  height: 21px;
  font-size: 16px;
  display: inline-block;
  background-color: #37a8f7;
  margin-right: 8px;
}
</style>