<template>
  <div class="searchCont">
    <div class="search-box">
      <el-input
        placeholder="搜索服务事项"
        v-focus
        v-model="forms.name"
        @keyup.enter.native="search"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="search"
        ></el-button>
      </el-input>
    </div>
    <div class="cont">
      <el-row class="list">
        <el-col
          :span="24"
          v-for="(item, index) in list"
          :key="index"
          style="cursor: pointer"
        >
          <div
            class="list-item"
            @click="toDetail(item.deptMatterId, item.name)"
          >
            <div>
              <!-- <span></span> -->
              {{ item.name }}
            </div>
            <div style="font-size: 14px; color: gray; margin: 10px 0">
              {{ item.deptName }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-empty v-show="total == 0" description="暂无匹配事项！"></el-empty>
    <el-pagination
      v-show="total > 0"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="forms.pageNum"
      :page-size="forms.pageSize"
      small
      layout="prev, pager, next, jumper"
      :total="total"
      style="margin-top: 20px"
    >
    </el-pagination>
  </div>
</template>

<script>
import { matterQuery } from "@/api/map/index";
import Vue from "vue";
Vue.directive("focus", {
  inserted: function (el) {
    el.querySelector("input").focus();
  },
});
export default {
  data() {
    return {
      forms: {
        name: "", //事项名称
        pageNum: 1,
        pageSize: 10,
        serviceTypeId: "",
        lifeCycleId: "",
        deptId: "",
        mapIds: [],
      },
      total: 0,
      list: [],
    };
  },
  created() {
    // if (this.$route.params) {
    //   this.forms.mapIds = this.$route.params.mapIds;
    // }
    // if (sessionStorage.getItem("mapIds")) {
    //   this.forms.mapIds = JSON.parse(
    //     sessionStorage.getItem("mapIds")
    //   );
    // }
    this.$forceUpdate();
  },
  mounted() {
    // this.search();
  },
  methods: {
    // 搜索事项
    search() {
      if (sessionStorage.getItem("mapIds")) {
         this.forms.mapIds = JSON.parse(
          sessionStorage.getItem("mapIds")
        );
       }
      let forms = JSON.parse(JSON.stringify(this.forms));
      forms.pageNum = forms.pageNum - 1;
      let obj = {
        method: "/mobile/matterQuery",
        body: JSON.stringify(forms),
      };
      matterQuery(obj).then((res) => {
        // console.log(res, "搜索事项");
        if (res.data.code == 200) {
          this.list = res.data.rows;
          this.total = res.data.total;
        } else {
          this.$alert(
            `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
            "",
            {
              dangerouslyUseHTMLString: true,
              center: true,
              showConfirmButton: false,
            }
          );
        }
      });
    },
    // 事项详情页
    toDetail(deptMatterId, name) {
      let obj = { deptMatterId: deptMatterId, name };
      this.$router.push({
        name: "ServiceGuide",
        params: obj,
      });
      sessionStorage.setItem("matterDetails", JSON.stringify(obj));
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.forms.pageSize = val;
      this.search();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.forms.pageNum = val;
      this.search();
    },
  },
};
</script>

<style  scoped>
.searchCont {
  margin: 20px;
}
.cont {
  margin: 25px 0;
}
.list-item {
  text-align: left;
  padding: 10px 0 0 0;
  border-bottom: 1px solid #dddddd;
}
.list-item span {
  width: 5px;
  height: 21px;
  font-size: 16px;
  display: inline-block;
  background-color: #37a8f7;
  margin-right: 8px;
}
</style>