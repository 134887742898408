  <template>
  <div v-loading="loading">
    <div class="banner">
      <p class="top-title" :title="matterName">{{ matterName }}</p>
    </div>
    <div class="steps_box">
      <el-steps :active="active" finish-status="success" align-center>
        <el-step
          :title="item.name"
          v-for="item in stepList"
          :key="item.index"
          :icon="item.icon"
          v-show="item.show"
        >
        </el-step>
      </el-steps>
    </div>
    <div class="cont">
      <el-form
        ref="form"
        class="form"
        :model="formData.applyEms"
        :rules="rules"
        label-position="left"
        label-width="90px"
      >
        <!-- 情形 -->
        <div class="form" v-show="active == 0">
          <guide :model="templateByMatterArr" @toGuide="toGuide" />
        </div>
        <!-- 表单 -->
        <div class="form" v-show="active == 1 && formData.applyData">
          <el-form-item label="姓名">
            <el-input
              disabled
              size="small"
              v-model="formData.applyData.personName"
              placeholder="请输入姓名"
            />
          </el-form-item>
          <el-form-item label="身份证号码">
            <el-input
              disabled
              size="small"
              v-model="formData.applyData.idCard"
              placeholder="请输入身份证号码"
            />
          </el-form-item>
          <el-form-item label="电话号码">
            <el-input
              size="small"
              disabled
              v-model="formData.applyData.phone"
              placeholder="请输入电话号码"
            />
          </el-form-item>
        </div>
        <!-- 材料 -->
        <div class="form" v-show="active == 2">
          <el-table
           :data="formData.submitMaterials"
           empty-text="该事项无需提供申请材料"
            style="width: 100%" border>
            <el-table-column label="材料名称" prop="materialName" />
            <el-table-column label="操作" width="100" align="center">
              <template slot-scope="scope">
              <div class="" v-if="scope.row.personFiles&&scope.row.personFiles.length>0">
                <el-popover
                   placement="left"
                   width="300"
                   v-model="scope.row.visible"
                   trigger="manual">
                    <div class="popover-container">
                      <div class="pop-close el-icon-close" @click="scope.row.visible=false"></div>
                      <div class="popover-item" v-for="item in scope.row.personFiles" :key="item">
                        <span @click="showDetail(item)" class="popover-text">{{item.name}}</span>
                        <span class="el-icon-circle-close close-icon" style="margin-left:10px" @click.stop="removeFile(item, '', scope.$index)"></span>
                      </div>
                    </div>
                  <span class="download_text" @click="scope.row.visible = !scope.row.visible" slot="reference">已上传</span>
                </el-popover>
<!-- <span  @click="showDetail(scope.row, 'detail')" class="download_text" slot="reference">已上传</span> -->
                <!-- <span style="margin-left:20px;"  @click="showDetail(scope.row, 'edit', scope.$index)" class="download_text" slot="reference">编辑</span> -->
              </div>
                <!-- <span class="download_text" v-if="scope.row.personFiles&&scope.row.personFiles.length>0">已上传</span> -->
                <!-- <el-button size="mini" type="text" v-if="scope.row.personFiles&&scope.row.personFiles.length>0">已上传</el-button > -->
                  <!-- accept=".xlsx, .xls, .doc, .docx, .pdf, .png, .jpg, .jpeg" -->
                <el-upload
                  class="upload-demo"
                  action=""
                  accept=".png, .jpg, .jpeg"
                  :http-request="
                    (file) => {
                      return submitUpload(file, scope.$index);
                    }
                  "
                  :on-change="handleChange"
                  :file-list="scope.row.personFiles"
                  :limit="20"
                  multiple
                  :show-file-list="false"
                  :on-exceed="handleExceed"
                  :before-upload="beforeFileUpload"
                  :before-remove="
                    (file, fileList) => {
                      return removeFile(file, fileList, scope.$index);
                    }
                  "
                >
                  <span class="download_text">上传图片</span>
                </el-upload>
                <el-upload
                  class="upload-demo"
                  action=""
                  :http-request="
                    (file) => {
                      return submitUpload(file, scope.$index);
                    }
                  "
                  :on-change="handleChange"
                  :file-list="scope.row.personFiles"
                  :limit="20"
                  :show-file-list="false"
                  :on-exceed="handleExceed"
                  :before-upload="beforeFileUpload"
                  :before-remove="
                    (file, fileList) => {
                      return removeFile(file, fileList, scope.$index);
                    }
                  "
                >
                  <span class="download_text">上传文件</span>
                </el-upload>
                <!-- accept=".xlsx, .xls, .doc, .docx, .pdf, .png, .jpg, .jpeg" -->
                <!-- <el-upload
                  v-if="scope.row.personFiles&&scope.row.personFiles.length>0"
                  class="upload-demo"
                  action=""
                  :http-request="
                    (file) => {
                      return submitUpload(file, scope.$index);
                    }
                  "
                  :on-change="handleChange"
                  :file-list="scope.row.personFiles"
                  :limit="20"
                  :show-file-list="false"
                  :on-exceed="handleExceed"
                  :before-upload="beforeFileUpload"
                  :before-remove="
                    (file, fileList) => {
                      return removeFile(file, fileList, scope.$index);
                    }
                  "
                >
                  <span class="download_text">重新上传</span>
                </el-upload> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 取件信息 -->
        <div class="form" v-if="active == 3">
          <el-form-item label="取件方式" prop="takesWay">
            <el-radio-group size="small" v-model="formData.applyEms.takesWay">
              <el-radio
                v-for="item in formData.applyData.takesOption"
                style="margin: 5px 10px 5px 0"
                :key="item"
                :label="item"
                :value="item"
                @change="changeRadio(item)"
                >{{
                  item == 0 ? "窗口领取" : item == 1 ? "邮寄" : "无需收件"
                }}</el-radio
              >
            </el-radio-group>
          </el-form-item>

          <div
            v-if="
              formData.applyEms.takesWay == 1 &&
              formData.applyEms.takesWay != null
            "
          >
            <el-form-item label-width="0">
              <el-button
                type="primary"
                style="float: left"
                size="mini"
                @click="getAddress"
                >选择地址</el-button
              >
            </el-form-item>
            <el-form-item label="联系人" prop="takesPerson">
              <el-input
                disabled
                size="small"
                v-model="formData.applyEms.takesPerson"
              />
            </el-form-item>
            <el-form-item label="电话号码" prop="takesPhone">
              <el-input
                disabled
                size="small"
                v-model="formData.applyEms.takesPhone"
              />
            </el-form-item>
            <el-form-item label="所在地区" prop="region">
              <div class="input">
                {{
                  formData.applyEms.region != null
                    ? formData.applyEms.region.length != 0
                      ? formData.applyEms.takesProvince +
                        formData.applyEms.takesCity +
                        formData.applyEms.takesCounty
                      : ""
                    : ""
                }}
              </div>
            </el-form-item>
            <el-form-item label="详细地址" prop="takesDetaile">
              <el-input
                size="small"
                disabled
                type="textarea"
                rows="3"
                resize="none"
                v-model="formData.applyEms.takesDetaile"
              />
            </el-form-item>
          </div>
        </div>
        <!-- 完成申请 -->
        <div class="form" v-show="active == 4">
          <div class="over">
            <!-- <img src="../../assets/img/over.png" /> -->
            <el-empty description="您的申请正在提交中, 系统提交成功后,
              您可以在个人中心-我的办事中查询相应办件状态及反馈"></el-empty>
            <!-- <span
              >您的申请正在提交中, 系统提交成功后,
              您可以在个人中心-我的办事中查询相应办件状态及反馈</span
            > -->
          </div>
        </div>
      </el-form>
    </div>
    <div class="flex-row">
      <el-button
        type="primary"
        size="medium"
        v-show="
          stepList[0].show ? active > 0 && active < 4 : active > 1 && active < 4
        "
        @click="Back"
        >上一步</el-button
      >
      <el-button
        type="primary"
        size="medium"
        v-show="active >= 0 && active < 3"
        @click="nextStep"
        :loading="loading"
        >下一步</el-button
      >
      <el-button
        type="primary"
        size="medium"
        v-show="active == 3"
        @click="submitFile"
        :loading="loading"
        >提交申请</el-button
      >
      <el-button
        type="primary"
        size="medium"
        v-show="active == 4"
        @click="comBack"
        >返回</el-button
      >
      <el-button
        type="primary"
        size="medium"
        v-show="active == 4"
        @click="toMyOffice"
        >我的办件</el-button
      >
    </div>
    <el-dialog
      title="选择地址"
      :visible.sync="open"
      width="320px"
      append-to-body
    >
      <div v-for="(item, index) in addressList" :key="index">
        <el-radio
          class="check"
          label="1"
          v-model="item.isDefault"
          @change="selectedDefaultVal(index)"
        >
          <div class="list-item">
            <span>{{ item.detailAddress }}</span>
            <span>{{ item.receiverName }}</span>
            <span>{{ item.receiverPhone }}</span>
          </div>
        </el-radio>
      </div>
      <el-button
        type="text"
        @click="Address"
        style="margin-top: 10px"
        size="mini"
        >新增地址</el-button
      >
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmAddress" size="mini"
          >确 定</el-button
        >
        <el-button @click="open = false" size="mini">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="新增地址"
      :visible.sync="openAddress"
      width="320px"
      append-to-body
    >
      <el-form
        ref="ruleForm"
        :rules="ruless"
        :model="ruleForm"
        label-width="110px"
      >
        <el-form-item label="联系人" prop="receiverName">
          <el-input
            v-model="ruleForm.receiverName"
            autocomplete="off"
            size="mini"
            placeholder="请输入联系人姓名"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="receiverPhone">
          <el-input
            v-model.number="ruleForm.receiverPhone"
            placeholder="请输入联系电话"
            size="mini"
            required
          ></el-input>
        </el-form-item>
        <el-form-item label="所在地区" prop="province">
          <el-cascader
            style="width: 100%"
            v-model="region"
            size="mini"
            :options="regionData"
            ref="cascader1"
            placeholder="请选择省市区"
            @change="handleRegion"
            clearable
          />
        </el-form-item>
        <el-form-item label="详细地址" prop="detailAddress">
          <el-input
            v-model.trim="ruleForm.detailAddress"
            placeholder="请输入详细地址"
            required
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="设为默认地址" prop="isDefault">
          <el-radio v-model="ruleForm.isDefault" label="1">是</el-radio>
          <el-radio v-model="ruleForm.isDefault" label="0">否</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="newAddress" size="mini"
          >确 定</el-button
        >
        <el-button @click="openAddress = false" size="mini">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="60%"
      >
        <div class="imgDialog">
          <img :src="appData.link + personFiles.httpUrl + '?' + personFiles.token"/>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  AddressList,
  saveAddresseeInfo,
  saveMobileBillInitializing,
  queryAddresseeInfo,
  saveMobileBillInitializingWithCommon,
  uploadCommon,
  addAddress,
} from "@/api/user/index";
import guide from "@/components/rollUp/guide";
import { regionData } from "element-china-area-data";
import { appGlobalData } from "@/utils/index";
export default {
  name: "OnlineApplication",
  components: { guide },
  data() {
    return {
      regionData,
      loading: false,
      loading: false,
      dialogVisible: false,
      active: 0,
      appData: appGlobalData,
      uploadReIds: [], // 保存等待上传的文件id
      uploadIds: [], // 保存上传过的文件id
      deptMatterId: this.$route.params.deptMatterId || null, //部门事项ID
      isQuestionaire: this.$route.params.isQuestionaire || false,

      open: false,
      openAddress: false,
      rollUp: [],
      templateByMatterArr: [],
      indexApplicationMaterial: 0,
      addressList: [], //地址列表
      billId: "",
      personImages: [], // 已上传图片
      personFiles: {}, // 已上传文件
      formData: {
        applyData: {},
        applyEms: {
          id: "",
          takesWay: null,
          takesPerson: "",
          takesPhone: "",
          takesProvince: "",
          takesCity: "",
          takesCounty: "",
          takesDetaile: "",
          region: "",
        },
        applyQuestionnaires: [],
        checkHistories: [],
        submitMaterials: [],
      },
      stepList: [
        {
          name: "情形选择",
          show: true,
          index: 0,
          icon: "el-icon-s-unfold",
        },
        {
          name: "表单填写",
          show: true,
          index: 1,
          icon: "el-icon-edit-outline",
        },
        {
          name: "材料上传",
          show: true,
          index: 2,
          icon: "el-icon-upload",
        },
        {
          name: "取件信息",
          show: true,
          index: 3,
          icon: "el-icon-info",
        },
        {
          name: "完成",
          show: true,
          index: 4,
          icon: "el-icon-success",
        },
      ], //步骤条列表

      rules: {
        takesPerson: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          {
            pattern: /^[\u4e00-\u9fa5]+$/,
            message: "请输入合法姓名！",
            trigger: "blur",
          },
        ],
        takesPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确手机号码！",
          },
        ],
        takesDetaile: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        region: [{ required: true, message: "请选择省市区", trigger: "blur" }],
        // takesWay: [
        //   { required: true, message: "请选择取件方式", trigger: "change" },
        // ],
      },
      region: [],
      ruleForm: {},
      ruless: {
        receiverName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          {
            pattern: /^[\u4e00-\u9fa5]+$/,
            message: "请输入合法姓名！",
            trigger: "blur",
          },
        ],
        receiverPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确手机号码！",
          },
        ],
        detailAddress: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        province: [
          { required: true, message: "请选择地区", trigger: "change" },
        ],
        isDefault: [
          { required: true, message: "请选择默认地址", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.$emit("public_header", true);
    if (sessionStorage.getItem("matterDetails")) {
      let matterDetails = JSON.parse(sessionStorage.getItem("matterDetails"));
      this.deptMatterId = matterDetails.deptMatterId;
      this.matterName = matterDetails.name;
      this.isQuestionaire = matterDetails.isQuestionaire;
      if (this.isQuestionaire) {
        this.templateByMatterArr = matterDetails.templateByMatterArr;
      } else {
        this.stepList[0].show = false;
        this.loading = true;
        let obj = {
          deptMatterId: this.deptMatterId,
          parentId: 0,
        };
        saveMobileBillInitializingWithCommon({
          method: "/mobile/saveMobileBillInitializingWithCommon",
          body: JSON.stringify(obj),
        }).then((res) => {
          if (res.data.code == 200) {
            this.billId = res.data.data.billId;
            this.queryAddresseeInfo();
          } else {
            this.loading = false;
            this.$alert(
              `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
              "",
              {
                dangerouslyUseHTMLString: true,
                center: true,
                showConfirmButton: false,
              }
            );
          }
        });
      }
    }
    this.$forceUpdate();
  },
  methods: {
    // 已上传文件查看详情
    showDetail (row) {
      this.personFiles = row;
      if (row.type === 'img') {
        this.dialogVisible = true;
      }
    },
    // 获取地址
    getAddress() {
      AddressList().then((res) => {
        if (res.data.code == 200) {
          this.addressList = res.data.data;
          this.open = true;
        } else {
          this.$alert(
            `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
            "",
            {
              dangerouslyUseHTMLString: true,
              center: true,
              showConfirmButton: false,
            }
          );
        }
      });
      this.$forceUpdate();
    },
    // 单选地址
    selectedDefaultVal(index) {
      this.indexApplicationMaterial = index;
      for (let i of this.addressList) {
        i.isDefault = "0";
      }
      this.addressList[index].isDefault = "1";
      this.$forceUpdate();
    },
    // 地址确认
    confirmAddress() {
      let index = this.indexApplicationMaterial;
      this.formData.applyEms.takesPerson = this.addressList[index].receiverName;
      this.formData.applyEms.takesPhone = this.addressList[index].receiverPhone;
      this.formData.applyEms.takesDetaile =
        this.addressList[index].detailAddress;
      this.formData.applyEms.takesProvince = this.addressList[index].province;
      this.formData.applyEms.takesCity = this.addressList[index].city;
      this.formData.applyEms.takesCounty = this.addressList[index].area;
      this.formData.applyEms.region =
        this.addressList[index].provinceCode +
        "," +
        this.addressList[index].cityCode +
        "," +
        this.addressList[index].areaCode;
      this.open = false;
      this.$forceUpdate();
    },
    // 新增地址
    Address() {
      this.resetForm();
      this.open = false;
      this.openAddress = true;
    },
    newAddress() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          addAddress(this.ruleForm).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.formData.applyEms.takesPerson = this.ruleForm.receiverName;
              this.formData.applyEms.takesPhone = this.ruleForm.receiverPhone;
              this.formData.applyEms.takesDetaile = this.ruleForm.detailAddress;
              this.formData.applyEms.takesProvince = this.ruleForm.province;
              this.formData.applyEms.takesCity = this.ruleForm.city;
              this.formData.applyEms.takesCounty = this.ruleForm.area;
              this.formData.applyEms.region =
                this.ruleForm.provinceCode +
                "," +
                this.ruleForm.cityCode +
                "," +
                this.ruleForm.areaCode;
              this.openAddress = false;
            } else {
              this.$alert(
                `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
                "",
                {
                  dangerouslyUseHTMLString: true,
                  center: true,
                  showConfirmButton: false,
                }
              );
              this.openAddress = false;
            }
          });
        }
      });
    },
    // 选择地区
    handleRegion(value) {
      let labelArr = this.$refs["cascader1"].getCheckedNodes()[0].pathLabels;
      this.ruleForm.province = labelArr[0];
      this.ruleForm.city = labelArr[1];
      this.ruleForm.area = labelArr[2];
      this.ruleForm.provinceCode = value[0];
      this.ruleForm.cityCode = value[1];
      this.ruleForm.areaCode = value[2];
    },
    // 重置表单
    resetForm() {
      this.ruleForm = {
        receiverName: "",
        receiverPhone: "",
        province: "",
        provinceCode: "",
        city: "",
        cityCode: "",
        area: "",
        areaCode: "",
        detailAddress: "", //详细地址
        isDefault: "",
      };
      this.region = [];
    },
    // 情形引导
    toGuide(val) {
      this.rollUp = val;
    },
    // 上传
    submitUpload(file, index) {
      var form = new FormData();
      form.append("file", file.file);
      // let flag = false;
      // for (let i = 0; i < this.uploadReIds.length; i++) {
      //   if (this.uploadIds.indexOf(this.uploadReIds[i]) !== -1) {
      //     flag = true;
      //   }
      // }
      // console.log('uploadIds', this.uploadIds);
      // console.log('flag', flag);
      // if (!flag && this.formData.submitMaterials[index]) {
      //   this.formData.submitMaterials[index].personFiles = []; // 正在上传第一个文件，重置当前上传文件为空
      // }
      // this.uploadIds.push(file.file.uid);
      this.loading = true;
      uploadCommon(form).then((res) => {
        if (res && res.data.code == 200) {
          let type = 'img'
          if ([ "png", "jpg", "jpeg",].indexOf(res.data.data.fileType) == -1) {
            type = 'file'
          }
          this.formData.submitMaterials[index].personFiles.push({
            name: res.data.data.name,
            filePath: res.data.data.filePath,
            httpUrl: res.data.data.httpUrl,
            url: res.data.data.httpUrl,
            fileId: res.data.data.fileId,
            fileType: res.data.data.fileType,
            token: res.data.data.token,
            type: type
          });
          // this.$message.success("上传成功！");
        }
        //  else {
        //   this.formData.submitMaterials[index].personFiles = [];
        // }
      }).finally(()=>{
        this.loading = false;
      });
      this.$forceUpdate();
    },
    // 上传格式校验
    beforeFileUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const whiteList = [
        // "png",
        // "jpg",
        // "jpeg",
        // "doc",
        // "docx",
        // "xls",
        // "ppt",
        // "pdf",
        // "wps",
        // "JPG",
        "png",
        "jpg",
        "jpeg",
        "doc",
        "dot",//new
        "docx",
        "xlsx",
        "xls",
        "xlt",//new
        "ppt",
        "pptx",//new
        "pdf",
        "ofd",//new
        "wps",
        "wpt",//new
        "rtf",//new
        "txt",//new
        // "rar",//new
        // "zip",//new
      ];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error(
          "上传文件只能是 png、jpg、jpeg、JPG、doc、docx、xls、ppt、pdf、wps格式"
        );
        return false;
      }
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error("上传文件大小不能超过 10MB");
        return false;
      }
    },
    // 文件状态改变时
    handleChange(file, fileList) {
      console.log('fileList', fileList);
      this.uploadReIds = fileList.map(item => item.uid);
      console.log('uploadReIds', this.uploadReIds);
    },
    // 删除上传文件
    removeFile(file, fileList, index) {
      const findex = this.formData.submitMaterials[index].personFiles
        .map((f) => f.name)
        .indexOf(file.name);
      if (findex > -1) {
        this.formData.submitMaterials[index].personFiles.splice(findex, 1);
        if (this.formData.submitMaterials[index].personFiles.length === 0) {
          this.formData.submitMaterials[index].visible = false;
        }
      }
    },
    // 文件超出个数限制时
    handleExceed(files, fileList) {
      // this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      this.$message.warning(`此项只能上传20个文件！`);
    },
    // 上一步
    Back() {
      if (this.stepList[this.active - 1].show == false) {
        //如果当前步骤的下一个步骤，显示状态为false,则跳过该步骤
        this.active -= 2;
      } else {
        this.active--;
      }
    },
    // 下一步
    async nextStep() {
      if (this.active == 0 && this.stepList[this.active].show == true) {
        this.loading = true;
        if (this.rollUp.length != 0) {
          let obj = {
            deptMatterId: this.deptMatterId,
            agencySingleDeptQuestionaireSelectSaveList: this.rollUp,
          };
          saveMobileBillInitializing({
            method: "/mobile/saveMobileBillInitializing",
            body: JSON.stringify(obj),
          }).then((res) => {
            if (res.data.code == 200) {
              this.billId = res.data.data.billId;
              this.queryAddresseeInfo();
            } else {
              this.loading = false;
              this.$alert(
                `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
                "",
                {
                  dangerouslyUseHTMLString: true,
                  center: true,
                  showConfirmButton: false,
                }
              );
            }
          });
          return;
        } else {
          this.loading = false;
          this.$message({
            message: "请选择后确认",
            type: "warning",
          });
          return;
        }
      }
      if (this.active == 1 && this.stepList[this.active].show == true) {
        this.active++;
        return;
      }
      if (this.active == 2 && this.stepList[this.active].show == true) {
        let isFalg = true;
        let num = 0;
        if (this.formData.submitMaterials.length != 0) {
          for (let i of this.formData.submitMaterials) {
            if (i.personFiles.length != 0) {
              num++;
            }
            if (num === this.formData.submitMaterials.length) {
              isFalg = false;
            }
          }
        }
        if (this.formData.submitMaterials.length == 0) {
          isFalg = false;
        }
        if (isFalg) {
          this.$message({
            message: "请上传材料后,下一步",
            type: "warning",
          });
          return;
        }
        this.active++;
      } else {
        this.active++;
      }
    },
    changeRadio (item) {
      this.formData.applyEms.takesWay = item;
    },
    // 获取材料
    queryAddresseeInfo() {
      let obj = {
        billId: this.billId,
      };
      queryAddresseeInfo({
        method: "/mobile/queryAddresseeInfo",
        body: JSON.stringify(obj),
      }).then((r) => {
        if (r.data.code == 200) {
          this.formData = r.data.data;
          if (localStorage.getItem("userInfo")) {
            let userInfo = JSON.parse(localStorage.getItem("userInfo"));
            this.formData.applyData.personName = userInfo.name;
            this.formData.applyData.phone = userInfo.phonenumber;
            this.formData.applyData.idCard = userInfo.idCard;
            this.formData.applyData.userId = userInfo.userId;
          }
          if (this.formData.applyData) {
            this.formData.applyData.takesOption = (
              this.formData.applyData.takesOption || ""
            ).trim().split(",");
            this.formData.applyEms.takesWay = this.formData.applyData.takesOption[0] || null;
          } else {
            this.formData.applyData = {};
          }
          this.loading = false;
          this.active++;
          this.$forceUpdate();
          return;
        } else {
          this.loading = false;
          this.$alert(
            `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
            "",
            {
              dangerouslyUseHTMLString: true,
              center: true,
              showConfirmButton: false,
            }
          );
        }
      });
    },

    // 提交申请
    async submitFile() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.formData.billId = this.billId;
          saveAddresseeInfo({
            method: "/mobile/saveAddresseeInfo",
            body: JSON.stringify(this.formData),
          }).then((res) => {
            this.loading = false;
            if (res.data.code == 200) {
              this.active++;
            } else {
              this.$alert(
                `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
                "",
                {
                  dangerouslyUseHTMLString: true,
                  center: true,
                  showConfirmButton: false,
                }
              );
            }
          });
        }
      });
      this.$forceUpdate();
    },
    // 返回办事指南
    comBack() {
      this.$router.go(-1);
    },
    // 查看我的办件
    toMyOffice() {
      this.$router.push({
        name: "MyOffice",
      });
    },
  },
};
</script>
<style scoped>
::v-deep .el-steps {
  justify-content: center;
}
.imgDialog{
  width: 100%;
  height: 200px;
  position: relative;
}
.imgDialog img{
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.flex-row {
  display: flex;
  justify-content: center;
}
.popover-item{
  padding: 5px 0;
  word-break: break-all;
}
.popover-container{
  position: relative;
  padding-top: 20px;
}
.popover-container .pop-close{
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;
}
.close-icon{
  color: red;
}
.banner {
  width: 100%;
  height: 121px;
  background: url("../../assets/img/titleBG.jpg") no-repeat;
  background-size: 100vw 17vh;
  display: flex;
  align-items: center;
}
.top-title {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  text-align: left;
  padding: 0 20px 0 30px;
}

.steps_box {
  margin: 10px auto 0 auto;
}

.form {
  margin: 20px 10px;
}

.cont {
  min-height: 400px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.download_text {
  color: #43a4ea;
  cursor: pointer;
}
.upload-demo {
  display: inline-block;
  margin-left: 10px;
}
.check {
  display: flex;
  align-items: center;
}

.list-item {
  text-align: left;
  padding: 15px 0;
  border-bottom: 1px solid #dddddd;
  display: flex;
  padding-left: 0;
  align-items: center;
  justify-content: space-between;
}

.list-item span {
  font-size: 12px;
  display: block;
  font-size: 12px;
  color: #999;
  text-align: left;
  margin-left: 10px;
}
.list-item span:nth-child(1) {
  color: #333;
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 0;
}
.over {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.over img {
  width: 173px;
  margin-top: 80px;
}
.over span {
  width: 300px;
  margin-top: 20px;
  color: #999;
  font-size: 12px;
}

.input {
  height: 32px;
  line-height: 32px;
  background-color: #f5f7fa;
  color: #c0c4cc;
  cursor: not-allowed;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 14px;
}
</style>