import request from "@/utils/request";
// 微信登录
export function wxLogin(query) {
  return request({
    url: "/auth/wechat/login",
    method: "post",
    data: query,
  });
}
// 用户注册
export function register(query) {
  return request({
    url: "/auth/wechat/register",
    method: "post",
    data: query,
  });
}
// 获取验证码
export function verifyCode(query) {
  return request({
    url: "/system/user/verify/code",
    method: "post",
    data: query,
  });
}
// 人脸识别
export function faceAuth(query) {
  return request({
    url: "/system/user/face/auth",
    method: "post",
    data: query,
  });
}
// 人脸识别会调
// 人脸识别
export function faceCallback(query) {
  return request({
    url: "/auth/wechat/face/callback",
    method: "post",
    data: query,
  });
}
// 获取用户信息
export function userInfo(query) {
  return request({
    url: "/system/user/wechat/info",
    method: "post",
    headers: {
      isToken: true
    },
    data: query,
  });
}

// 查询地图点位列表（地图1.0）
export function queryList(query) {
  return request({
    url: "/transfer/common/unauthorized",
    method: "post",
    data: query,
  });
}
// 获取微信签名
export function weixinKey(query) {
  return request({
    url: "/system/wx/jssdk/config?url="+query,
    method: "get",
    data: query,
  });
}
// 根据类型查询地图点位
export function conditionTypeQuery(query) {
  return request({
    url: "/transfer/common/unauthorized",
    method: "post",
    data: query,
  });
}
// 查询地图点位列表（地图1.0）
export function regionVillagePoint(query) {
  return request({
    url: "/transfer/common/unauthorized",
    method: "post",
    data: query,
  });
}

//   根据服务类型和生命周期、点位查询事项
export function matterQuery(query) {
  return request({
    url: "/transfer/common/unauthorized",
    method: "post",
    data: query,
  });
}

// 获取生命周期
export function lifeCycleMatter(query) {
  return request({
    url: "/transfer/common/unauthorized",
    method: "post",
    data: query,
  });
}

//   事项详情  post  参数 matterId  事项id
export function matterInfo(query) {
  return request({
    url: "/transfer/common/unauthorized",
    method: "post",
    data: query,
  });
}
//     post  不传参  部门列表
export function GetdeptList(query) {
  return request({
    url: "/transfer/common/unauthorized",
    method: "post",
    data: query,
  });
}
// 查询点位是否有事项--展示事项公开
export function queryDeptMatterRegion(query) {
  return request({
    url: "/transfer/common/unauthorized",
    method: "post",
    data: query,
  });
}
// /zwmap/matterMapQuery  地图查询事项  post  参数 name  事项名字或者地址
export function matterMapQuery(query) {
  return request({
    url: "/zwmap/matterMapQuery",
    method: "post",
    data: query,
  });
}

// 办事材料上传
export function uploadMaterialFile(query) {
  return request({
    url: "/zwmap/uploadMaterialFile",
    method: "post",
    data: query,
  });
}

// 登录
export function doLogin(query) {
  return request({
    url: "/zwmap/wechat/toLogin",
    method: "post",
    data: query,
  });
}
// 用户基本信息绑定
export function saveUserInfo(query) {
  return request({
    url: "/zwmap/binder",
    method: "post",
    data: query,
  });
}
// 获取乡镇列表--无参
export function getVillages(query) {
  return request({
    url: "/transfer/common/unauthorized",
    method: "post",
    data: query,
  });
}
// 特色乡村--获取服务器当前时间，根据当前月判断季节--无参
export function getServerDate(query) {
  return request({
    // url: "/zwmap/getDate",
    url: `/transfer/common/unauthorized`,
    method: "post",
    data: query,
  });
}

// 问卷引导列表
export function getOneLevelQuestionnaire(data) {
  return request({
    url: `/transfer/common/unauthorized`,
    method: "post",
    data: data,
  });
}

// 问卷引导提交
export function getQuestionnaireMaterial(data) {
  return request({
    url: `/transfer/common/unauthorized`,
    method: "post",
    data: data,
  });
}
// 非鉴权接口
export function unauthorized(data) {
  return request({
    url: `/transfer/common/unauthorized`,
    method: "post",
    data: data,
  });
}