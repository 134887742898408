<template>
  <div id="app" class="container">
    <!-- <head-banner v-if="header_show"></head-banner> -->
    <router-view
      class="Site-content"
      v-on:public_header="public_header"
      v-on:public_footer="public_footer"
    />
    <!-- <foot-banner v-if="footer_show"></foot-banner> -->
    <div style="height: 3rem"></div>
  </div>
</template>

<script>
import HeadBanner from "@/components/HeadBanner/index";
import FootBanner from "@/components/FootBanner/index";
// import { queyrList } from "@/api/map/index";
export default {
  name: "App",
  components: { FootBanner, HeadBanner },
  data() {
    return {
      header_show: true,
      footer_show: true,
    };
  },
  methods: {
    //是否显示头部
    public_header(bool) {
      this.header_show = bool;
    },
    // 是否显示底部
    public_footer(bool) {
      this.footer_show = bool;
    },
  },
};
</script>

<style>
/* 表格单元格为null时候 数据显示无 */
.el-table .el-table__body td .cell:empty::after,
.el-table .el-table__body td .cell > span:empty::after,
.el-table .el-table__body td .cell .approval:empty::after,
.el-table .el-table__body td .cell div:empty::after,
.el-table .el-table__body td .cell .line-limit-length:empty::after {
  content: "无";
}
/* 步骤条样式 */
.el-step__title.is-wait {
  color: #000;
}
/* 地图信息窗样式 */
.amap-info-content {
  width: 330px;
}
.el-step.is-simple .el-step__title {
  font-size: 15px;
}
.el-step__icon.is-text {
  border: none;
}
.mapBtn {
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
  padding: 7px 15px;
  font-size: 12px;
  border-radius: 3px;
  float: right;
  border: none;
}
.detail_item {
  font-size: 14px;
  margin: 5px 0;
}
.detail_item img {
  margin-right: 8px;
}
/* 当前步骤样式 */
.el-step__title.is-process {
  font-weight: 700;
  color: #0090ff;
}
/* 已完成步骤 */
.el-step__title.is-finish {
  color: #000;
}
/* 个人中心侧边栏样式 */
.el-tabs__item {
  font-size: 16px;
  font-weight: 550;
}
/* 个人中心位置*/
.el-dropdown {
  display: inline-block;
  position: absolute;
  color: #606266;
  font-size: 14px;
  right: 0;
  top: 30px;
}
/*上传组件图标样式
.el-icon-plus:before {
  content: "\e6d9";
  background-color: #1876FB;
  color: #fff;
  border-radius: 50%;
}*/
/*上传组件样式*/
.el-upload--picture-card {
  width: 100%;
  height: auto;
  line-height: 136px;
}
.container {
  max-width: 750px;
  margin: 0 auto;
}
.topTitle {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
}
body,
p {
  margin: 0;
}

a {
  color: #000;
  text-decoration: none;
}

ul,
li {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}
.blackTips {
  font-size: 12px;
  margin: 10px 0px 20px 0px;
  text-align: left;
}
.grayTips {
  font-size: 12px;
  text-align: left;
  margin: 10px 0 20px 0;
  color: gray;
}
/* element弹窗宽度 */
.el-message-box {
  width: 90%;
}
/* 级联选择 */
.el-cascader-menu {
  min-width: 130px;
  width: 130px;
}
</style>
