<template>
  <div style="min-width:1200px;">
    <div class="head">
      <div class="container ">
        <!-- <div class="search  fr" style="display:none;">
          <form id="_f_search" method="get" class="mt5 mb5 pb5" style="padding-top:80px;"
            action="http://www.hanyuan.gov.cn/search.html">
            <div class="form-inline border bg-white" style="height:36px;">
              <select name="t" v-model="type" class="form-control border-0 fl" id="searchType">
                <option value="">默认搜索</option>
                <option value="zwxx">政务信息</option>
                <option value="zcwj">政策文件</option>
                <option value="bsfw">办事服务</option>
                <option value="image">图片</option>
              </select>
              <input type="text" name="q" class="form-control   border-0 " id="txtKeyword" v-model="keywords" value=""
                placeholder="请输入您要搜索的内容">
              <input class=" vam  pr10 fr mt5 " type="image" src="http://www.hanyuan.gov.cn/v2/images/icon_search.png"
                title="搜素" @click="toSearch()">
            </div>
          </form>
        </div>
        <div class="fl pt40 ">
          <a href="http://www.hanyuan.gov.cn">
            <img src="http://www.hanyuan.gov.cn/v2/images/logo.png" alt=""></a>
        </div> -->
        <div class="fl pt40">
          <a href="http://www.hanyuan.gov.cn">
            <img src="../../assets/img/whiteLogo.png" alt=""></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HeadBanner',
  data() {
    return {
      type: '',  //搜索类型
      keywords: '',
      options: [
        {
          name: '默认搜索',
          value: '',
        },
        {
          name: '政务信息',
          value: 'zwxx',
        },
        {
          name: '政策文件',
          value: 'zcwj',
        },
        {
          name: '办事服务',
          value: 'bsfw',
        },
        {
          name: '图片',
          value: 'image',
        }
      ]
    };

  },

  mounted() {

  },

  methods: {
    toSearch() {
      window.location.href = `http://www.hanyuan.gov.cn/search.html?t=${this.tyep}&q=${this.keywords}`
    },
  },
};
</script>
<style scoped>
.head{
  height: 285px;
  background: url('../../assets/img/heade-banner.jpg');
  background-size: 100% 100%;
}
.container {
  width: 1200px;
  height: 98%;
  margin-right: auto;
  margin-left: auto;
}

.search {
  width: 430px;
}

.fr {
  float: right !important;
}

.pt40 {
  padding-top: 40px !important;
}

.fl {
  float: left !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.bg-white {
  background-color: #fff;
}

.border-0 {
  border: 0 !important;
}

.search input[type='text'] {
  width: 280px;
}

.form-control {
  height: 34px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  box-shadow: inset 0px 1px 1px rgb(0 0 0 / 8%);
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
}

.pr10 {
  padding-right: 10px !important;
}

.vam {
  vertical-align: middle !important;
}
</style>