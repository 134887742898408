<template>
  <div class="footbg">
    <div class="footBox pt30 pb30">
      <div class="fl lh2 ml60">
        <div class="pt10">
          <!-- <a href="http://www.hanyuan.gov.cn/v2/wza/default.html">无障碍浏览</a> -->
          <!-- <span class="ml10 mr10">|</span> -->
          <a href="http://www.hanyuan.gov.cn/sitemap.html">站点地图</a>
          <span class="ml10 mr10">|</span>
          <a href="http://www.hanyuan.gov.cn/gongkai/show/20180117090537-755237-00-000.html">网站使用帮助</a>
          <span class="ml10 mr10">|</span>
          <a href="http://www.hanyuan.gov.cn/en/">English</a>
        </div>
        <span class="pr20">主办：汉源县人民政府办公室 </span>承办：汉源县政府办电子政务科（0835-4223061）

        <br />
        <a href="https://beian.miit.gov.cn/">蜀ICP备09006193号-1</a>
        <span class="pl20 pr20">政府网站标识码：5118230001</span>
        <a target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51182302511839">川公网安备51182302511839</a>
        <br />
        Copyright @ hanyuan.gov.cn All Rights Reserved
        <br />
      </div>

      <div class="fr pt20 mr60">
        <a
          href="https://zfwzgl.www.gov.cn/exposure/jiucuo.html?site_code=5118230001&url=http%3A%2F%2Fwww.hanyuan.gov.cn%2F">
          <img onclick="Link('5118230001')" style="margin: 0; border: 0; cursor: pointer"
            src="https://zfwzgl.www.gov.cn/exposure/images/jiucuo.png?v=5118230001" />
        </a>
        <span id="_ideConac"><a
            href="https://bszs.conac.cn/sitename?method=show&amp;id=5CCECAB11F04187CE053022E1AACBE5B"
            target="_blank"><img id="imgConac" vspace="0" hspace="0" border="0"
              src="https://dcs.conac.cn/image/red_error.png" data-bd-imgshare-binded="1" /></a></span>
        <span id="_span_jiucuo"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FootBanner',
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {

  },
};
</script>
<style scoped>
/* 底部 */
img {
  vertical-align: middle;
}

.pt10 {
  padding-top: 10px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.footbg {
  background: #f7f7f7;
  height: 198px;
  clear: both;
}

.footBox {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 1170px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  clear: both;
  text-align: left;
  box-sizing: border-box;
  height: 198px;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.lh2 {
  line-height: 2;
}

.ml60 {
  margin-left: 60px;
}

.mr60 {
  margin-right: 60px;
}
</style>