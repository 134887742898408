<template>
  <div id="app" v-loading='isLoading'>
    <div class="topBanner">
      <!-- <img style="width: 100%" src="../assets/img/indexBanner.png" alt="" /> -->
      <div class="banner">
        <el-carousel :height="imgHeight">
          <el-carousel-item v-for="(item,index) in bannerImgList" :key=index>
            <img
              width="100%"
              v-lazy="item.imgUrl" ref="imgH" @load="imgLoad"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="topcon">
        <div v-if="$store.state.name" class="isLoginBtn">
        <!-- <div v-if="userInfo.name" class="isLoginBtn"> -->
          <router-link style="color: #fff" to="/personal"
            >*{{ $store.state.name.substring(1) }}，您好>></router-link
          >
        </div>
        <div v-else class="isLoginBtn" @click="toRegist">请登录</div>
        <span class="isFaceAuth" v-if="$store.state.name && $store.state.faceAuth == '1'">
          <img src="../assets/img/card_icon.png" alt="" srcset="" />
          已人脸认证</span
        >
        <span
          class="isFaceAuth"
          v-show="$store.state.name && $store.state.faceAuth == 0"
        >
          <img src="../assets/img/card_icon.png" alt="" srcset="" />
          已实名认证</span
        >
      </div>
      <img class="index_logo" src="../assets/img/index_logo_bg.png" alt="" />
    </div>
    <div style="margin: 20px 0; text-align: center">
      <img style="width: 25%" src="../../src/assets/img/bsfw.png" alt="" />
    </div>

    <div class="box1-cont">
      <img
        @click="toHanYuan"
        style="width: 50%; margin-right: 20px"
        src="../assets/img/xianbenji.png"
        alt=""
      />
      <img
        @click="toVillages"
        style="width: 50%"
        src="../assets/img/xiangzhen.png"
        alt=""
      />
    </div>
    <div style="text-align: center; margin: 20px 0">
      <img style="width: 32%" src="../assets/img/zwdt.png" alt="" />
    </div>
    <div style="margin: 0 20px">
      <img
        @click="toMap"
        width="100%"
        src="../assets/img/mapEnter.png"
        alt=""
      />
    </div>
    <div style="text-align: center; margin: 20px 0">
      <img style="width: 25%" src="../assets/img/leave_title_1.png" alt="" />
    </div>
    <div style="margin: 0 20px">
      <img
        @click="toMessage"
        width="100%"
        src="../assets/img/leave_message.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import * as req from "@/api/map/index";
import { encrypt, decrypt } from "@/utils/rsaUtils";
import { appGlobalData } from "@/utils/index";
import { mixin } from "../mixin";
export default {
  name: "index",

  data() {
    return {
      activeName: "1",
      isLoading: false,
      personList: [
        "其他",
        "工作",
        "升学",
        "购房",
        "结婚",
        "生育",
        "失业",
        "创业",
        "迁居",
        "退休",
        "后事",
      ],
      legalPersonList: [
        "开办企业",
        "申请资质",
        "投资立项",
        "扩大生产",
        "引进人才",
        "办理社保",
        "申请专利",
        "缴纳税费",
        "申请破产",
        "申请贷款",
      ],
      code: "",
      // appid: "wxd5baa829d8869161",
      appid: appGlobalData.appId,
      errMsg: "请输入正确身份证号码",
      bannerImgList:[
      {imgUrl:require('../assets/img/index/banner1.jpg')},
      {imgUrl:require('../assets/img/index/banner2.jpg')},
      {imgUrl:require('../assets/img/index/banner3.jpg')},
      {imgUrl:require('../assets/img/index/banner4.jpg')},
      {imgUrl:require('../assets/img/index/banner5.jpg')},
      ],
      // userInfo: JSON.parse(localStorage.getItem("userInfo")) || {}
    };
  },
  mixins: [mixin],
  created() {
    if (
      localStorage.getItem("loginInfo") &&
      JSON.parse(localStorage.getItem("loginInfo")).sessionId
    ) {
      // console.log('已登录',this.$store.state.name)
      // 重新获取用户信息（刷新是否获取人脸认证）
      this.getUserInfo(this.$store.state.sessionId);
      // this.getCode(true);
    } else {
      // 获取授权code
      this.getCode();
    }
    this.$forceUpdate();
    // 获取用户信息
  },
  methods: {
    // 获取微信授权code
    async getCode(type) {
      // var local = "http://hanyuan.fengrenkeji.cn";
      var local = appGlobalData.URL;
      // console.log('local', local)
      this.code = "";
      this.code = this.getUrlCode().code; // 截取code
      let spoce = 'snsapi_base';
      if (!type) {
        spoce = 'snsapi_userinfo';
      }
      if (this.code == null || this.code === "") {
        //   // 如果没有code，则去请求
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          this.appid +
          "&redirect_uri=" +
          encodeURIComponent(local) +
          "&response_type=code&scope="+spoce+"#wechat_redirect";
      } else {
        // alert(this.code);
        // 将code传给后端  获取秘钥
        await req.wxLogin({ code: this.code }).then((res) => {
          // alert(JSON.stringify(res) + "微信登录结果");
          if (res.data.code == 200) {
            if (res.data.token && res.data.token.access_token) {
              //已登录注册过
              this.$store.commit("SET_TOKEN", res.data.token.access_token);
              this.$store.commit("SET_SESSIONID", res.data.sessionId);
              this.$store.commit("SET_PUBLICKEY", res.data.publicKey);
              localStorage.removeItem("loginInfo");
              localStorage.setItem(
                "loginInfo",
                JSON.stringify({
                  sessionId: res.data.sessionId,
                  publicKey: res.data.publicKey,
                  token: res.data.token.access_token,
                })
              );
            } else {
              //已登录未注册
              this.$store.commit("SET_SESSIONID", res.data.sessionId);
              this.$store.commit("SET_PUBLICKEY", res.data.publicKey);
              localStorage.removeItem("loginInfo");
              localStorage.setItem(
                "loginInfo",
                JSON.stringify({
                  sessionId: res.data.sessionId,
                  publicKey: res.data.publicKey,
                })
              );
            }
            // 获取用户信息
            this.getUserInfo(res.data.sessionId);
          } else {
            this.$alert(
              `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
              "",
              {
                dangerouslyUseHTMLString: true,
                center: true,
                showConfirmButton: false,
              }
            );
          }
        });
      }
    },
    // 获取用户信息
    getUserInfo(sessionId) {
      this.isLoading = true;
      req.userInfo({ sessionId }).then((res) => {
        // console.log(res, "用户信息");
        // alert(JSON.stringify(res) + "用户信息");
        this.isLoading = false;
        if ((res.data.code == 200)) {
          if (res.data.data && res.data.data.token) {
            let loginInfo = localStorage.getItem("loginInfo")?JSON.parse(localStorage.getItem("loginInfo")) : {}
            if (loginInfo.token && loginInfo.token != res.data.data.token) {
              loginInfo.token = res.data.data.token;
              this.$store.commit("SET_TOKEN", res.data.data.token);
              localStorage.setItem(
                "loginInfo",
                JSON.stringify({
                  sessionId: loginInfo.sessionId,
                  publicKey: loginInfo.publicKey,
                  token: loginInfo.token
                })
              );
            }
          }
          if (res.data.data.sysUserVO && res.data.data.sysUserVO.name) {
            //已注册
            this.$store.commit("SET_NAME", res.data.data.sysUserVO.name);
            this.$store.commit(
              "SET_FACEAUTH",
              res.data.data.sysUserVO.faceAuth
            );
            this.$store.commit(
              "SET_PHONE",
              res.data.data.sysUserVO.phonenumber
            );
            this.$store.commit("SET_IDCARD", res.data.data.sysUserVO.idCard);
            // this.userInfo = res.data.data.sysUserVO;
            localStorage.setItem(
              "userInfo",
              JSON.stringify({
                name: res.data.data.sysUserVO.name,
                phonenumber: res.data.data.sysUserVO.phonenumber,
                idCard: res.data.data.sysUserVO.idCard,
                faceAuth: res.data.data.sysUserVO.faceAuth,
              })
            );
          } else if (res.data.data.sysUserVO.realAuth != "1") {
            localStorage.removeItem("userInfo");
            // this.userInfo = {};
            // this.$router.push("/register");
          }
          // 首次进入记录浏览量
          if (!sessionStorage.getItem('isLoad')) {
            let sysUserVO = res.data.data.sysUserVO || {}
            let params = {
              userId: sysUserVO.userId || '',
              userName: sysUserVO.name || '',
              phoneNumber: sysUserVO.phonenumber || ''
            }
            req.unauthorized({
              method: '/mobile/saveVisitData',
              body: JSON.stringify(params)
            })
            sessionStorage.setItem('isLoad', 'true')
          } 
        } else {
          if (res.data.code == 500) {
            localStorage.removeItem("loginInfo");
            localStorage.removeItem("userInfo");
            this.getCode(true);
          } else {
          this.$alert(
            `<i class="el-icon-warning" style="color:red;font-size:50px"></i> <div style="color:red;">${res.data.msg}</div>`,
            "",
            {
              dangerouslyUseHTMLString: true,
              center: true,
              showConfirmButton: false,
            }
          );
          }

        }
      }).catch(() => {
        this.isLoading = false;
      });
    },
    //截取url中的code方法
    getUrlCode() {
      var url = location.search;
      // console.log(url);
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      this.code = theRequest.code;
      return theRequest;
    },
    // 测试加密解码
    async getTest() {
      const s = { name: "hh", sex: 0, age: 12 };
      //const pubKey = res.data.signature
      const pubKey =
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCMWSLRlc7y4mA6XDI3hwCxNz2Cb0WsZA081lDaXKKZFafIruPyn+gn5cFBP6vaT7n4J48WrTFsyAlXrjEubt8la28J0N6Z9wgxFnNhtB1Qs2By8IYkc1Ixicm2RPmsV0PoxlVzWOTTieeYHxyjnLmVYhq9mYirYucvl4EprB5V3QIDAQAB";
      const s1 = encrypt(JSON.stringify(s), pubKey);
      console.log("加密后的字符串：" + s1);
      //const priKey = res.data.privateKey
      const priKey =
        "MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBAIxZItGVzvLiYDpcMjeHALE3PYJvRaxkDTzWUNpcopkVp8iu4/Kf6CflwUE/q9pPufgnjxatMWzICVeuMS5u3yVrbwnQ3pn3CDEWc2G0HVCzYHLwhiRzUjGJybZE+axXQ+jGVXNY5NOJ55gfHKOcuZViGr2ZiKti5y+XgSmsHlXdAgMBAAECgYA9NWqwCSvWVdAt46Oq/9/XagYTar/yLThwve9JLHllVkJ0jYGwgPjY3aTo9gHXFko+5dHLzADwNlWMvdWJMKV0niRNewDbjxyr77S2RV2SStYIWJwWqLgSdtZKFl6u+TteEwAD9cbNKKmkw8fUJA08IuCa+oYK3yoJlT8uueHO6QJBAOSiqW3mRaXc/XhhpfHL51IYBGZvdMGpjj3mbJsqxI0PeJMDpbF68ky3GhsV+gU5BmB9DVD6bWFbfWN7+vmQy18CQQCdJV+fXvM1fZNqL7Pk9sFQJ6PFrrlAowCihy/Tihsl9D4zcbLNvcutFkpE5ydPFYGyF4TMLIe6BwFMEjAROGRDAkEAoGIuwkY3c0ubjxfIxd6IjYEFjSoAWF3txyRd6VdN+7nvlgmH8QFS+Ve0e22mLoW7IHJ4peaWViZMxj3RZ8IXtwJAWR1xnaItz963s1b78FMSi07/a8JK4eHOIV0CfTbrcFmS0H+gw1XnY++pDGv9ebabhxAZSePS0GbakHYb9hgBmwJBALeTBkhuQID+QgPLPfFQldiJxiFU13ax2cZcxGJPDZLssOKyJcge59eZb6bf1qnn731JcFLRoe1AHf6E8/o4nCA=";
      const s2 = decrypt(s1, priKey);
      console.log(s2);
    },
    // 个人中心
    toPersonal() {
      this.$router.push("/Personal");
    },
    toFaceAuth() {
      this.$router.push("/faceAuthentication");
    },
    // 去注册
    toRegist() {
      this.$router.push("/register");
    },
    // 到县本级
    toHanYuan() {
      this.$router.push("/CharacteristicVillage/HanYuan");
    },
    // 到乡镇
    toVillages() {
      this.$router.push("/villagesAndTowns");
    },
    // 到地图
    toMap() {
      this.$router.push("/map");
    },
    // 留言反馈
    toMessage () {
      // this.$router.push("/feedBack");
      if (
        localStorage.getItem("loginInfo") &&
        JSON.parse(localStorage.getItem("loginInfo")).token
      ) {
        this.$router.push("/feedBack");
      } else {
        this.$confirm("未实名认证，请进行实名认证", "系统提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          location.href = "#/register";
        });
      }
    }
  },
};
</script>

<style scoped>
.topBanner {
  position: relative;
}
.index_logo {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  width: 47px;
}
.topcon {
  position: absolute;
  left: 0px;
  bottom: 30px;
  z-index: 1000;
}
.isLoginBtn {
  text-align: left;
  margin-bottom: 10px;
  color: #fff;
  background-color: #0091fe;
  padding: 5px 10px;
  border-radius: 5px;
  display: block;
  cursor: pointer;
}
.isFaceAuth {
  color: #fff;
  display: flex;
  align-items: center;
  margin-left: 10px;
  justify-content: center;
  font-size: 14px;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
}
.isFaceAuth img {
  width: 20px;
  margin-right: 5px;
}
.rllabe {
  color: #fff;
  display: inline-block;
  border-radius: 5px;
  padding: 5px;
  background-color: #0091fe;
  box-shadow: 5px 1px 14px 3px rgba(0, 0, 0, 0.31);
  margin-right: 10px;
  font-size: 12px;
}
.qylabe {
  color: #fff;
  background-color: #f59a23;
  display: inline-block;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 5px 1px 14px 3px rgba(0, 0, 0, 0.31);
  font-size: 12px;
}
.rllabe {
  color: #fff;
  display: inline-block;
  padding: 5px;
  margin-right: 10px;
  font-size: 14px;
  display: flex;
}
.box1-cont {
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box1-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 25px 25px 0 0;
  border-bottom: 1px solid lightgray;
}
.box1-topItem {
  width: 50%;
  height: 70px;
  line-height: 70px;
  font-size: 17px;
  font-weight: bold;
}
.box1-con {
  height: 330px;
  width: 100%;
  border: 1px solid;
}
.box2-title {
  margin: 20px;
  text-align: left;
}
.box2 {
  height: 150px;
  border: 1px solid;
  border-radius: 5px;
  margin: 0 20px;
}
/deep/.el-carousel__indicator.is-active button {
  background-color: #143a96;
}
/deep/.el-carousel__button {
  background-color: gray;
  width: 20px !important;
}
</style>
